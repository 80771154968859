import * as Three from 'three';
import { AnimationService, AssetsService, GameInfoService, GameObjectClass, MathService, MathUtils, ParserService, PhysicsWrapper, RenderService } from 'three-default-cube';
import { AlchemyCombination, ItemsService } from '../../services/items-service';
import { UntypedDefaultCube } from '../../types';
import { InteractionType } from '../environment/interaction';
import { ItemGameObject } from '../environment/item';
import { InteractionTargetWrapper } from '../property-wrappers/interaction-target-wrapper';
import { HeroGameObject, SlotId } from './hero';

export class AlchemyMachineGameObject extends GameObjectClass {
  pivot: Three.Object3D = null;
  model: Three.Object3D = null;
  outputSlotModel: Three.Object3D = null;
  physics = null;

  slots: Array<ItemGameObject> = [];
  activeCombination?: AlchemyCombination = null;
  combinationProgress = 0.0;
  combinationCounter = null;

  constructor() {
    super();

    this.pivot = <UntypedDefaultCube>this;

    this.physics = new PhysicsWrapper(this.pivot);
    this.physics.enableNavmaps();

    (<UntypedDefaultCube>AssetsService).getModel(GameInfoService.config.models.alchemyMachine, { forceUniqueMaterials: true })
    .then(model => {
      this.pivot.visible = true;

      this.pivot.add(model);

      (<UntypedDefaultCube>ParserService).parseModel({
        target: model,
        gameObjects: {
          'tube': (object) => {
            (<UntypedDefaultCube>AnimationService).registerAnimation({
              target: object,
              onStep: ({ target }) => {                
                const machineActive = this.activeCombination;

                target.scale.y = MathUtils.lerp(target.scale.y, machineActive ? (this.combinationProgress * 0.9 + 0.1) : 0.1, 0.1);
              }
            });
          },
          'bubble': (object) => {
            object.material.transparent = true;
            object.material.opacity = 0.5;

            (<UntypedDefaultCube>AnimationService).registerAnimation({
              target: object,
              randomSeed: Math.random() * 10.0,
              onCreate: ({ target }) => {
                target.userData.position = target.position.clone();
              },
              onStep: ({ target, animationTime }) => {
                target.position.y = target.userData.position.y + Math.sin(animationTime * 0.1) * 0.25;
              }
            });
          },
          'slot1': (object) => {
            new InteractionTargetWrapper(object, [InteractionType.attack], ({ sourceObject }) => {
              if (!(sourceObject instanceof HeroGameObject)) {
                return;
              }

              this.putItemInSlot({ slotObject: object, slotId: 1, sourceHero: sourceObject });
            });
          },
          'slot2': (object) => {
            new InteractionTargetWrapper(object, [InteractionType.attack], ({ sourceObject }) => {
              if (!(sourceObject instanceof HeroGameObject)) {
                return;
              }

              this.putItemInSlot({ slotObject: object, slotId: 2, sourceHero: sourceObject });
            });
          },
          'slot3': (object) => {
            this.outputSlotModel = object;
          },
          'pump': (object) => {
            const { pumpSlotId } = object.userData;

            (<UntypedDefaultCube>AnimationService).registerAnimation({
              target: object,
              onStep: ({ target, animationTime }) => {                
                const slotActive = this.slots[pumpSlotId];

                let intensity = target.material.emissiveIntensity;

                if (!slotActive) {
                  intensity = 0.0;
                } else {
                  intensity = (Math.sin(animationTime) + 1.0) * 0.25 + 0.5;
                }

                target.material.emissiveIntensity = MathUtils.lerp(target.material.emissiveIntensity, intensity, 0.1);
              }
            });
          }
        }
      });

      this.model = model;

      this.onCreate();
    });
  }

  onCreate() {
    this.physics = new PhysicsWrapper(this.pivot);
    this.physics.enableNavmaps();
  }

  putItemInSlot({ slotObject, slotId, sourceHero }: {
    slotObject: Three.Object3D,
    slotId: number,
    sourceHero: HeroGameObject
  }) {
    const heldItem: UntypedDefaultCube = sourceHero.removeItemFromSlot(SlotId.weapon);

    if (!heldItem) {
      return;
    }

    const scene = RenderService.getScene();
    
    const slotPosition = MathService.getVec3();
    slotObject.getWorldPosition(slotPosition);

    heldItem.quaternion.identity();

    scene.add(heldItem);
    heldItem.position.copy(slotPosition);
    heldItem.position.y += 1.0;

    heldItem.enablePickUp(() => {
      this.slots[slotId]= null;

      this.checkAlchemyProcess();
    });

    this.slots[slotId] = heldItem;

    this.checkAlchemyProcess();
  }

  checkAlchemyProcess() {
    this.combinationProgress = 0.0;
    this.activeCombination = ItemsService.findMatchingAlchemyCombination(this.slots[1], this.slots[2]);

    if (this.combinationCounter) {
      clearInterval(this.combinationCounter);
    }

    if (this.activeCombination) {
      this.combinationCounter = setInterval(() => {
        if (this.combinationProgress >= 1.0) {
          clearInterval(this.combinationCounter);

          this.slots.forEach((item, index) => {
            if (item) {
              ItemsService.destroyItem(item);
            }

            this.slots[index] = null;
          });

          const scene = RenderService.getScene();
          const pivotPosition = MathService.getVec3();
          this.outputSlotModel.getWorldPosition(pivotPosition);

          const item = ItemsService.createItem(this.activeCombination.itemC);
          item.position.copy(pivotPosition);
          item.enablePickUp();

          scene.add(item);

          this.checkAlchemyProcess();

          return;
        }

        this.combinationProgress += 0.01;
      }, this.activeCombination.seconds / 100.0);
    }
  }
}
