import * as Three from 'three';
import { GameInfoService, GameObjectClass, AssetsService, ParserService, UiService, mathPi2, Text, VarService, AnimationService, TimeService, MathService } from 'three-default-cube';
import { UntypedDefaultCube } from '../../types';

export class DialogueUi extends GameObjectClass {
  textBatches = [];
  dialoguePromise = null;

  constructor() {
    super();

    UiService.registerUiElement(this);
    this.position.z -= 5.0;

    (<UntypedDefaultCube>AssetsService).getModel(GameInfoService.config.models['dialogueUi'], { forceUniqueMaterials: true })
    .then((model) => {
      this.add(model);

      (<UntypedDefaultCube>ParserService).parseModel({
        target: model,
        gameObjects: {
          'dialogueBox': (object) => {
            const dialogText = new Text((<UntypedDefaultCube>{
              color: '#241e19',
              textAlign: 'center',
              fontSize: 0.17,
              text: '',
              outlineWidth: 5.0,
              outlineColor: '#c2a267'
            }));
            dialogText.position.z += 0.1;
            dialogText.position.x -= 1.6;
            dialogText.position.y += 0.6;
            dialogText.troikaText.anchorY = 'middle' as any; // NOTE Ugly, but necessary

            VarService.getVar('dialogue-text', (value) => {
              if (value) {
                dialogText.troikaText.text = value;
                model.visible = true;
              } else {
                dialogText.troikaText.text = '';
                model.visible = false;
              }
            });

            object.add(dialogText);

            (<UntypedDefaultCube>AnimationService).registerAnimation({
              target: object,
              onCreate: ({ target }) => {
                target.userData.position = target.position.clone();
              },
              onStep: ({ target, animationTime }) => {
                target.position.y = target.position.y + Math.sin(animationTime * 0.5) * 0.05;
              }
            });
          },
          'dialogueButton': (object) => {
            (<UntypedDefaultCube>AnimationService).registerAnimation({
              target: object,
              onStep: ({ target }) => {
                const initialScale = MathService.getVec3(1.0, 1.0, 1.0);

                target.scale.lerp(initialScale, 0.2);
              }
            })
          }
        },
        actions: {
          'dialogueNext': (object) => {
            if (object.visible) {
              this.updateDialogue();

              object.scale.setScalar(1.1);
            }
          },
          'dialogueCancel': (object) => {
            if (object.visible) {
              this.cancelDialogue();

              object.scale.setScalar(1.1);
            }
          }
        }
      });
    });
  }

  getBatchDialogStrings(text: string): string[] {
    const hardLimit = 42;
    const batches = [];
    let batch = '';

    const words = text.split(' ');

    while (words.length > 0) {
      const word = words.shift();

      if (batch.length + word.length + 1 < hardLimit) {
        batch += ` ${word}`;
      } else {
        if (word.length + 1 >= hardLimit) {
          const wordBreak = hardLimit - word.length;

          batch += ` ${word.substr(0, wordBreak)}`;
          batches.push(batch);

          batch = `${word.substr(wordBreak)}`;
        } else {
          batches.push(batch);

          batch = word;
        }
      }
    }

    if (batch) {
      batches.push(batch);
    }

    return batches;
  }

  showDialogue(textOrArray: string | string[]): Promise<any> {
    this.textBatches = [];

    if (typeof textOrArray === 'string') {
      const batches = this.getBatchDialogStrings(textOrArray);

      while (batches.length) {
        this.textBatches.push(`${batches.splice(0, 3).join('\n')}${(batches.length > 0) ? '...' : ''}`);
      }
    } else if (textOrArray instanceof Array) {
      textOrArray.forEach(set => {
        const batches = this.getBatchDialogStrings(set);

        while (batches.length) {
          this.textBatches.push(`${batches.splice(0, 3).join('\n')}${(batches.length > 0) ? '...' : ''}`);
        }
      });
    }

    this.textBatches = this.textBatches.filter(Boolean);

    const promised = new Promise(resolve => {
      this.dialoguePromise = resolve;
    });

    this.updateDialogue();

    return promised;
  }

  updateDialogue() {
    if (!this.dialoguePromise) {
      return;
    }

    if (this.textBatches.length === 0) {
      this.dialoguePromise(true);
      this.hideDialogue();

      return;
    }

    const text = this.textBatches.shift();

    VarService.setVar('dialogue-text', text);
    VarService.setVar('dialogue-next', this.textBatches.length > 0);
    VarService.setVar('dialogue-finish', this.textBatches.length === 0);
    VarService.setVar('dialogue-cancellable', true);
  }

  cancelDialogue() {
    if (this.dialoguePromise) {
      this.dialoguePromise(false);
    }

    this.hideDialogue();
  }

  hideDialogue() {
    this.dialoguePromise = null;

    VarService.setVar('dialogue-text', '');
    VarService.setVar('dialogue-next', false);
    VarService.setVar('dialogue-finish', false);
    VarService.setVar('dialogue-cancellable', false);
  }
}
