import * as Three from 'three';
import { VarService } from 'three-default-cube';
import { HeroGameObject } from '../game-objects/creatures/hero';
import { MountGameObject } from '../game-objects/creatures/mount';
import { ZoneId } from './zone-service';

class PlayerServiceClass {
  playerInfo: Record<string, unknown> = {};
  playerObject?: HeroGameObject;
  playerMountObject?: MountGameObject;

  async init() {
    const initialPlayerInfo = VarService.getVar('playerInfo');

    if (initialPlayerInfo) {
      this.setPlayerInfo(initialPlayerInfo);
    }

    VarService.setVar('playerUpdated', 0);
  }

  setPlayerInfo(info: Record<string, unknown>) {
    this.playerInfo = JSON.parse(JSON.stringify(info));

    VarService.setVar('playerInfo', this.playerInfo);
  }

  getPlayerInfo(): Record<string, unknown> {
    return this.playerInfo;
  }

  getPlayerZone(): ZoneId {
    return (this.playerInfo.zone as ZoneId) || ZoneId.zoneIslaBeliarek;
  }

  setPlayerZone(zone: ZoneId) {
    this.playerInfo.zone = zone;
  }

  getPlayerObject(): HeroGameObject {
    return this.playerObject;
  }

  setPlayerObject(target: HeroGameObject) {
    this.playerObject = target;
  }
  
  getPlayerMountObject(): MountGameObject {
    return this.playerMountObject;
  }

  setPlayerMountObject(target: MountGameObject) {
    this.playerMountObject = target;
  }

  getPlayerGold() {
    return (this.playerInfo.gold as number) || 0;
  }

  setPlayerGold(newGold: number) {
    this.playerInfo.gold = newGold;

    this.updatePlayer();
  }

  updatePlayer() {
    VarService.setVar('playerUpdated', VarService.getVar('playerUpdated') + 1);
  }
}

export const PlayerService = new PlayerServiceClass();
