import * as Three from 'three';
import { AssetsService, createDefaultCube, GameObjectClass, MathService, PhysicsService, PhysicsWrapper } from 'three-default-cube';

export enum InteractionType {
  attack = 'attack',
  explosion = 'explosion'
};

export class InteractionGameObject extends GameObjectClass {
  physics = null;
  reachPivot = null;
  sourceObject = null;
  interactionType = null;
  triggered = false;

  constructor(sourceObject, interactionType: InteractionType, interactionReact: number = 1.0) {
    super();

    if (!sourceObject || !interactionType) {
      return;
    }

    this.reachPivot = createDefaultCube(this, 'reach-pivot', { size: interactionReact });
    this.reachPivot.visible = false;

    this.interactionType = interactionType;
    this.sourceObject = sourceObject;

    this.physics = new PhysicsWrapper(this.reachPivot);
    this.physics.enableDynamicCollisions((hit) => {
      if (this.triggered || !hit.userData.interactionTarget || !hit.userData.interactionTarget.allowedInteractions.includes(this.interactionType)) {
        return;
      }

      hit.userData.interactionTarget.onInteraction({ interactionPivot: this.reachPivot, hit, sourceObject });
      this.triggered = true;

      AssetsService.disposeAsset(this);
    });
  }

  cancel() {
    this.triggered = true;
    AssetsService.disposeAsset(this);
  }
}