import * as Three from 'three';
import { AnimationService, GameObjectClass, mathPi2, MathService, MathUtils, RenderService } from 'three-default-cube';
import { ItemId, ItemsService } from '../../services/items-service';
import { UntypedDefaultCube } from '../../types';
import { TargettableWrapper } from '../property-wrappers/targettable-wrapper';

const chests: Record<string, {
  itemDrop: ItemId
}> = {
  banjerHideoutChest: {
    itemDrop: 'axe-1'
  },
  banjerHideoutAlchemistChest1: {
    itemDrop: 'alchemist-helmet-1'
  },
  banjerHideoutAlchemistChest2: {
    itemDrop: 'alchemist-robes-1'
  },
  banjerHideoutAlchemistChest3: {
    itemDrop: 'alchemist-staff-1'
  }
};

export class ChestGameObject extends GameObjectClass {
  model: Three.Object3D;

  constructor(object) {
    super();

    this.model = object;

    this.onCreate();
  }

  onCreate() {
    const { propChestId } = this.model.userData;
    
    const pivot = new Three.Group();
    pivot.position.copy(this.model.position);
    pivot.quaternion.copy(this.model.quaternion);
    
    this.model.position.set(0.0, 0.0, 0.0);
    this.model.quaternion.identity();
    
    this.model.parent.add(pivot);
    pivot.add(this.model);
    
    const targettable = new TargettableWrapper(this.model, () => {
      this.model.userData.opened = !this.model.userData.opened;
      targettable.disable();
    
      const itemDrop = chests[propChestId].itemDrop;
    
      if (itemDrop) {
        const scene = RenderService.getScene();
        const pivotPosition = MathService.getVec3();
        this.model.getWorldPosition(pivotPosition);
    
        const item = ItemsService.createItem(itemDrop);
        item.position.copy(pivotPosition);
        item.enablePickUp();
    
        scene.add(item);
    
        MathService.releaseVec3(pivotPosition);
      }
    });
    
    (<UntypedDefaultCube>AnimationService).registerAnimation({
      target: this.model,
      onStep: () => {
        const { opened } = this.model.userData;
    
        this.model.rotation.x = MathUtils.lerp(this.model.rotation.x, opened ? -mathPi2 : 0.0, 0.2);
      }
    });
  }
}