import * as Three from 'three';
import { AnimationService, AssetsService, RenderService, GameObjectClass } from 'three-default-cube';
import { ItemsService } from '../../services/items-service';
import { InteractionGameObject, InteractionType } from '../environment/interaction';
import { ItemGameObject } from '../environment/item';

export class ExplosiveWrapper extends GameObjectClass {
  explosive =  null;

  constructor(target: ItemGameObject) {
    super();

    this.explosive = target;

    this.triggerCountdown();
  }

  triggerCountdown() {
    const countdownAnimation = AnimationService.registerAnimation({
      target: this.explosive,
      onCreate: ({ target }) => {
        target.traverse(child => {
          if (child.material) {
            child.material = AssetsService.cloneMaterial(child.material);
            child.material.emissive = new Three.Color(0xff0000);
          }
        });
      },
      onStep: ({ target, time, animationTime }) => {
        target.traverse(child => {
          if (child.material) {
            child.material.emissiveIntensity = Math.sin(time * animationTime * 2.0) * 0.5 + 1.0;
          }
        });
      }
    });

    setTimeout(() => {
      this.triggerExplosion();

      AnimationService.cancelAnimation(countdownAnimation);
    }, 3000);
  }

  triggerExplosion() {
    ItemsService.destroyItem(this.explosive);

    const interaction = new InteractionGameObject(this, InteractionType.explosion, 5.0);
    this.explosive.getWorldPosition(interaction.position);
    
    const scene = RenderService.getScene();
    scene.add(interaction);

    setTimeout(() => {
      interaction.cancel();
    }, 1000);
  }
}