import { AssetsService, GameObjectClass, MathService, RenderService, TimeService, VarService } from "three-default-cube";
import { ItemsService } from "../../services/items-service";

export class ItemSpawnGameObject extends GameObjectClass {
  constructor(object, {
    showCaseMode = false
  } = {}) {
    super();

    const { propItemSpawn, propItemSpawnMinDelay, propItemSpawnRandomDelay, propItemSpawnEnablePickUp } = object.userData;
    let spawnInterval = propItemSpawnMinDelay + Math.random() * propItemSpawnRandomDelay;

    object.visible = false;

    let spawnedItem = null;

    const spawnItem = () => {
      if (spawnedItem) {
        return;
      }
      
      VarService.resolveVar(propItemSpawn, itemId => {
        const itemExists = ItemsService.getItem(itemId);

        if (!itemId || !itemExists) {
          return;
        }

        const scene = RenderService.getScene();
        const pivotPosition = MathService.getVec3();
        object.getWorldPosition(pivotPosition);

        const item = ItemsService.createItem(itemId);
        item.position.copy(pivotPosition);

        if (propItemSpawnEnablePickUp === 'false') {
          item.disablePickUp();

          if (showCaseMode) {
            item.enableShowCase();
          }
        } else {
          item.enablePickUp();
        }

        spawnedItem = item;

        scene.add(item);

        MathService.releaseVec3(pivotPosition);
      });
    };

    if (spawnInterval) {
      TimeService.registerIntervalListener(spawnItem, spawnInterval);
    } else {
      spawnItem();
    }

    AssetsService.registerDisposeCallback(object, () => TimeService.disposeIntervalListener(spawnItem, spawnInterval));
  }
}
