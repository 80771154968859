import { VarService } from 'three-default-cube';
import { HeroActivityEnums } from '../game-objects/creatures/hero';
import { CameraMode } from '../game-views/world';
import { PlayerService } from './player-service';
import { WhyDungeonsUiService } from './whydungeons-ui-service';

class FishingServiceClass {
  startFishing({ onSuccess }: {
    onSuccess: () => void
  }) {
    const playerObject = PlayerService.getPlayerObject();

    VarService.setVar('cutscene', true);
    VarService.setVar('cameraMode', CameraMode.dialogue);

    playerObject.setActivity(HeroActivityEnums.fish);

    WhyDungeonsUiService.fishingUi.show();

    const pending = setTimeout(() => {
      this.triggerCatch(onSuccess);
    }, Math.random() * 5000 + 5000);
    
    WhyDungeonsUiService.fishingUi.onFishingButtonClick(() => {
      clearTimeout(pending);
      this.finishFishing();
    });
  }

  triggerCatch(onSuccess?: () => void) {
    const playerObject = PlayerService.getPlayerObject();

    playerObject.setActivity(HeroActivityEnums.fishCatch);

    const pending = setTimeout(() => {
      this.finishFishing();
    }, Math.random() * 500 + 500);

    WhyDungeonsUiService.fishingUi.onFishingButtonClick(() => {
      clearTimeout(pending);

      if (onSuccess) {
        onSuccess();
      }

      this.finishFishing();
    });
  }

  finishFishing() {
    const playerObject = PlayerService.getPlayerObject();
    
    WhyDungeonsUiService.fishingUi.onFishingButtonClick(null);
    WhyDungeonsUiService.fishingUi.hide();

    VarService.setVar('cutscene', false);
    VarService.setVar('cameraMode', CameraMode.follow);

    playerObject.setActivity(null);
  }
}

export const FishingService = new FishingServiceClass();