import * as Three from 'three';
import { AnimationService, AssetsService, RenderService, GameObjectClass, ParticleService, MathUtils, MathService } from 'three-default-cube';
import { ItemsService } from '../../services/items-service';
import { InteractionGameObject, InteractionType } from '../environment/interaction';
import { ItemGameObject } from '../environment/item';

export class ParticleEmitterWrapper extends GameObjectClass {
  pivot = null;

  constructor(target: Three.Object3D) {
    super();

    this.pivot = target;

    this.createParticleEmitter();
  }

  createParticleEmitter() {
    const { propParticleType, propParticleOpacity } = this.pivot.userData;

    const baseOpacity = propParticleOpacity || 0.02;
    const baseColor = {
      'shadow': [
        0xff0040,
        0x8c00ff
      ],
      'poison': [
        0x00ff51,
        0x00ffcc
      ],
      'fire': [
        0xffb300,
        0xfa1702
      ]
    }[propParticleType];

    const dustParticle = new Three.Mesh(
      new Three.SphereBufferGeometry(0.5, 3, 2),
      new Three.MeshBasicMaterial({
        color: baseColor[0],
        opacity: baseOpacity,
        transparent: true,
        fog: false
      })
    );
  
    this.dustParticleEmitter = ParticleService.registerParticleEmitter(this.pivot, {
      particleObject: dustParticle,
      particleDensity: 200,
      positionJitter: 0.5,
      spawnJitter: 1.0,
      scaleJitter: 1.0,
      globalTransforms: true,
      onCreate: ({ target }) => {
        target.material = AssetsService.cloneMaterial(target.material);
        target.material.opacity = baseOpacity;

        const up = MathService.getVec3(0.0, 0.0, 1.0);

        target.userData.direction = new Three.Vector3(
          MathUtils.randFloat(-1.0, 1.0),
          MathUtils.randFloat(-1.0, 1.0),
          MathUtils.randFloat(-1.0, 1.0),
        )
        .lerp(up, 0.5)
        .normalize()
        .multiplyScalar(0.01);

        MathService.releaseVec3(up);
      },
      onReset: ({ target }) => {
        target.material.color.set(baseColor[0]);
        target.material.opacity = baseOpacity;
      },
      onFrame: ({ target, lifeTime }) => {
        target.position.add(target.userData.direction);
        target.material.opacity = baseOpacity - (lifeTime * baseOpacity);
        target.material.color.lerpColors(
          new Three.Color(baseColor[0]),
          new Three.Color(baseColor[1]),
          Math.min(1.0, target.material.opacity + 0.2)
        );
  
        return lifeTime < 1;
      }
    });
  }
}