import * as Three from 'three';
import { AssetsService, createDefaultCube, InteractionEnums, InteractionsService, VarService, GameObjectClass } from 'three-default-cube';
import { PlayerService } from '../../services/player-service';
import { UntypedDefaultCube } from '../../types';

export class TargettableWrapper extends GameObjectClass {
  target: Three.Object3D = null;
  minDistance = 3.0;
  enabled = true;

  onReached?: (() => Promise<void>) | (() => void);

  constructor(target, onReached: (() => Promise<void>) | (() => void)) {
    super();

    this.target = target;
    this.onReached = onReached;
    this.enabled = true;

    this.onCreate();
  }

  onCreate() {
    const interactionCube = createDefaultCube(this.target, 'interactionListener', <UntypedDefaultCube>{
      size: 2.0
    });
    interactionCube.position.y += 1.0;
    interactionCube.visible = false;

    InteractionsService.registerInvisibleListener(
      interactionCube,
      InteractionEnums.eventClick,
      ({ stopPropagation }) => {
        if (!this.enabled) {
          return;
        }

        const cutscene = VarService.getVar('cutscene');

        stopPropagation();

        if (!cutscene) {
          this.onInteraction();
        }
      }
    );

    AssetsService.registerDisposeCallback(interactionCube, () => InteractionsService.disposeListener(interactionCube));
  }

  onInteraction() {
    const playerObject = PlayerService.getPlayerObject();

    VarService.setVar('cutscene', true);

    playerObject.moveToTarget(this.target, this.minDistance).then(() => {
      if (typeof this.onReached === 'function') {
        const result = this.onReached();

        if (result && result.then) {
          return result;
        } else {
          return Promise.resolve(result);
        }
      } else {
        return Promise.resolve();
      }
    }).then(() => {
      VarService.setVar('cutscene', false);
    });
  }

  enable() {
    this.enabled = true;
  }

  disable() {
    this.enabled = false;
  }
}