import { AnimationWrapper, AssetsService, GameInfoService, GameObjectClass, RenderService, replacePlaceholder, VarService } from 'three-default-cube';
import { InteractionType } from './interaction';
import { InteractionTargetWrapper } from '../property-wrappers/interaction-target-wrapper';
import { ItemsService } from '../../services/items-service';
import { PlayerService } from '../../services/player-service';
import { SlotId } from '../creatures/hero';

export class FireGameObject extends GameObjectClass {
  constructor({ object }) {
    super();

    new InteractionTargetWrapper(object, [InteractionType.attack], ({ interactionPivot }) => {
      const playerObject = PlayerService.getPlayerObject();
      const heldItem = playerObject.getItemInSlot(SlotId.weapon);

      if (heldItem.userData.itemId === 'log-1') {
        ItemsService.destroyItem(heldItem);

        const torch = ItemsService.createItem('torch-1');
        playerObject.putItemInSlot(SlotId.weapon, torch);
      }
    });
  }
}