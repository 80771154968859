import * as Three from 'three';
import { VarService } from 'three-default-cube';

export enum ZoneId {
  zoneIslaBeliarek = 'zoneIslaBeliarek',
  banjerHideout = 'banjerHideout',
}

class ZoneServiceClass {
  currentZone?: ZoneId;
  minimap?: Three.Object3D;
  playerSpawnTarget?: Three.Object3D;
  zoneChangeListener?: (zone: ZoneId) => void;

  setZone(zoneId: ZoneId) {
    if (zoneId === this.currentZone) {
      return;
    }

    this.currentZone = zoneId;

    if (this.zoneChangeListener) {
      this.zoneChangeListener(this.currentZone);
    }
  }

  setPlayerSpawnTarget(targetId) {
    this.playerSpawnTarget = targetId;
  }

  getPlayerSpawnTarget() {
    return this.playerSpawnTarget;
  }

  onZoneChange(callback) {
    this.zoneChangeListener = callback;
  }

  setMinimapObject(object: Three.Object3D) {
    this.minimap = object;

    VarService.setVar('zoneMinimapObject', object);
  }

  getMinimapObject(): Three.Object3D {
    return this.minimap;
  }
}

export const ZoneService = new ZoneServiceClass();