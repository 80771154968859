import * as Three from 'three';
import { AssetsService, GameObjectClass } from 'three-default-cube';
import { HeroGameObject, SlotId } from '../game-objects/creatures/hero';
import { ItemGameObject } from '../game-objects/environment/item';
import { UntypedDefaultCube } from '../types';
import { PlayerService } from './player-service';

export type ItemId = string;

export type Item = {
  modelId: string;
  props?: Array<ItemProperty>;
  slot?: SlotId;
  onPickUp?: (source: HeroGameObject) => void;
  name: string;
  shopPrice?: number;
  iconId?:
    'sword-1'
    | 'helmet-1'
    | 'chest-1'
    | 'shield-1'
    | 'axe-1'
    | 'staff-1'
    | 'pauldron-1'
    | 'unknown-1';
  rarity: 1 | 2 | 3;
}

export enum ItemProperty {
  item,
  weapon,
  weaponThrowable,
  weaponExplosive,
  offhand,
  helmet,
  armor,
  pauldrons,
  food,
  fruit,
};

export const items: Record<ItemId, Item> = {
  'alchemist-helmet-1': {
    modelId: 'alchemist-helmet-1',
    slot: SlotId.head,
    name: 'Alchemist Hood',
    shopPrice: 1000,
    iconId: 'helmet-1',
    rarity: 2,
  },
  'alchemist-robes-1': {
    modelId: 'alchemist-robes-1',
    slot: SlotId.chest,
    name: 'Alchemist Robes',
    shopPrice: 1000,
    iconId: 'chest-1',
    rarity: 2,
  },
  'alchemist-staff-1': {
    modelId: 'alchemist-staff-1',
    slot: SlotId.offhand,
    name: 'Alchemist Staff',
    shopPrice: 1000,
    iconId: 'staff-1',
    rarity: 2,
  },
  'alchemist-wings-1': {
    modelId: 'alchemist-wings-1',
    name: 'Alchemist Wings',
    shopPrice: 1000,
    rarity: 2,
  },
  'apple-1': {
    modelId: 'apple-1',
    name: 'Apple',
    shopPrice: 5,
    rarity: 1,
  },
  'axe-1': {
    modelId: 'axe-1',
    name: 'Woodcutter\'s Axe',
    shopPrice: 50,
    iconId: 'axe-1',
    rarity: 1,
  },
  'bomb-1': {
    modelId: 'bomb-1',
    name: 'Iron Bomb',
    shopPrice: 50,
    props: [
      ItemProperty.weaponThrowable,
      ItemProperty.weaponExplosive
    ],
    rarity: 1,
  },
  'cape-1': {
    modelId: 'cape-1',
    name: 'Cape',
    shopPrice: 1000,
    rarity: 1,
  },
  'fish-1': {
    modelId: 'fish-1',
    name: 'Carp',
    shopPrice: 5,
    rarity: 1,
  },
  'fishing-rod-1': {
    modelId: 'fishing-rod-1',
    name: 'Fishing Rod',
    shopPrice: 50,
    rarity: 1,
  },
  'helmet-1': {
    modelId: 'helmet-1',
    slot: SlotId.head,
    name: 'Heroic Helmet',
    shopPrice: 1000000,
    iconId: 'helmet-1',
    rarity: 3,
  },
  'horse-head-helmet-1': {
    modelId: 'horse-head-helmet-1',
    name: 'Horse Helmet',
    shopPrice: 1000000,
    iconId: 'helmet-1',
    rarity: 3,
  },
  'iron-ore-1': {
    modelId: 'iron-ore-1',
    name: 'Iron Ore',
    shopPrice: 25,
    rarity: 1,
  },
  'key-1': {
    modelId: 'key-1',
    name: 'Key',
    rarity: 1,
  },
  'log-1': {
    modelId: 'log-1',
    name: 'Wood',
    shopPrice: 5,
    rarity: 1,
  },
  'pauldron-1': {
    modelId: 'pauldron-1',
    slot: SlotId.shoulderL,
    name: 'Heroic Pauldrons',
    shopPrice: 1000000,
    rarity: 3,
    iconId: 'pauldron-1',
  },
  'pauldron-2': {
    modelId: 'pauldron-2',
    slot: SlotId.shoulderR,
    name: 'Heroic Pauldrons',
    shopPrice: 1000000,
    rarity: 3,
    iconId: 'pauldron-1',
  },
  'pickaxe-1': {
    modelId: 'pickaxe-1',
    name: 'Pickaxe',
    shopPrice: 50,
    rarity: 1,
  },
  'platebody-1': {
    modelId: 'platebody-1',
    slot: SlotId.chest,
    name: 'Heroic Platebody',
    shopPrice: 1000000,
    iconId: 'chest-1',
    rarity: 3,
  },
  'shield-1': {
    modelId: 'shield-1',
    slot: SlotId.offhand,
    name: 'Heroic Shield',
    shopPrice: 1000000,
    iconId: 'shield-1',
    rarity: 3,
  },
  'sword-2b': {
    modelId: 'sword-2b',
    name: 'Sword 2B',
    shopPrice: 10000,
    iconId: 'sword-1',
    rarity: 2,
  },
  'torch-1': {
    modelId: 'torch-1',
    name: 'Torch',
    shopPrice: 50,
    iconId: 'staff-1',
    rarity: 1,
  },
  'trident-1': {
    modelId: 'trident-1',
    name: 'Trident',
    shopPrice: 23000,
    iconId: 'sword-1',
    rarity: 2,
  },
  'vase-1': {
    modelId: 'vase-1',
    name: 'Vase',
    shopPrice: 100,
    rarity: 1,
  },
  'gold-1': {
    modelId: 'placeholder-1',
    name: 'Gold Coins',
    onPickUp: (source) => {
      const playerObject = PlayerService.getPlayerObject();

      if (source === playerObject) {
        PlayerService.setPlayerGold(PlayerService.getPlayerGold() + Math.floor(Math.random() * 50));
      }
    },
    rarity: 1,
  },
  'witch-staff-1': {
    modelId: 'witch-staff-1',
    name: 'Witch Staff',
    slot: SlotId.offhand,
    shopPrice: 23000,
    iconId: 'staff-1',
    rarity: 2,
  },
  'axe-2': {
    modelId: 'axe-2',
    name: 'War Axe',
    shopPrice: 150,
    iconId: 'axe-1',
    rarity: 1,
  },
  'axe-3': {
    modelId: 'axe-3',
    name: 'Great Axe',
    shopPrice: 2500,
    iconId: 'axe-1',
    rarity: 1,
  },
  'hammer-1': {
    modelId: 'hammer-1',
    name: 'War Hammer',
    shopPrice: 2500,
    iconId: 'axe-1',
    rarity: 1,
  },
  'hat-1': {
    modelId: 'black-hat',
    name: 'Black Hat',
    slot: SlotId.head,
    iconId: 'helmet-1',
    rarity: 3,
  },
  'spear-1': {
    modelId: 'pole-1',
    name: 'Primitive Spear',
    shopPrice: 100,
    iconId: 'staff-1',
    rarity: 1,
  },
  'shield-2': {
    modelId: 'shield-2',
    name: 'Wooden Shield',
    slot: SlotId.offhand,
    shopPrice: 100,
    iconId: 'shield-1',
    rarity: 1,
  },
  'shield-3': {
    modelId: 'shield-3',
    name: 'Soldier Shield',
    slot: SlotId.offhand,
    shopPrice: 2500,
    iconId: 'shield-1',
    rarity: 1,
  },
  'shield-4': {
    modelId: 'shield-4',
    name: 'Spike Shield',
    slot: SlotId.offhand,
    shopPrice: 7500,
    iconId: 'shield-1',
    rarity: 2,
  },
  'shield-5': {
    modelId: 'shield-5',
    name: 'Knight Shield',
    slot: SlotId.offhand,
    shopPrice: 17000,
    iconId: 'shield-1',
    rarity: 2,
  },
  'shield-book-1': {
    modelId: 'shield-book-1',
    name: 'Holy Shield',
    slot: SlotId.offhand,
    shopPrice: 4500,
    iconId: 'shield-1',
    rarity: 2,
  },
  'sword-2': {
    modelId: 'sword-2',
    name: 'Great Zweihander',
    shopPrice: 23000,
    iconId: 'sword-1',
    rarity: 1,
  },
  'sword-3': {
    modelId: 'sword-3',
    name: 'Long Sword',
    shopPrice: 4500,
    iconId: 'sword-1',
    rarity: 1,
  },
  'sword-4': {
    modelId: 'sword-4',
    name: 'Short Sword',
    shopPrice: 1000,
    iconId: 'sword-1',
    rarity: 1,
  },
  'banner-1': {
    modelId: 'banner-1',
    name: 'Heroic Banner',
    rarity: 3,
  }
};

export type AlchemyCombination = {
  itemA: ItemId,
  itemB: ItemId,
  itemC: ItemId,
  seconds: number
};

const itemAlchemyCombinations: Array<AlchemyCombination> = [
  {
    itemA: 'iron-ore-1',
    itemB: 'torch-1',
    itemC: 'bomb-1',
    seconds: 10000
  }
];

class ItemsServiceClass {
  createItem(itemId: string): ItemGameObject {
    return new ItemGameObject({ itemId });
  }

  destroyItem(item: ItemGameObject) {
    const trashBin = new Three.Group();

    trashBin.add(item as UntypedDefaultCube);

    AssetsService.disposeAsset(trashBin);
  }

  isItem(item: Three.Object3D) {
    return item && item.userData && !!item.userData.itemId;
  }

  hasProperty(item: ItemGameObject, property: ItemProperty): boolean {
    if (!item || !item.userData || !item.userData.props) {
      return false;
    }

    return item.userData.props.indexOf(property) !== -1;
  }

  getItem(itemId: ItemId): Item {
    return items[itemId];
  }

  findMatchingAlchemyCombination(itemA: ItemGameObject, itemB: ItemGameObject): AlchemyCombination {
    if (!itemA || !itemB) {
      return;
    }

    return itemAlchemyCombinations.find((combination) => {
      if (combination.itemA === itemA.userData.itemId && combination.itemB === itemB.userData.itemId) {
        return combination;
      }

      if (combination.itemB === itemA.userData.itemId && combination.itemA === itemB.userData.itemId) {
        return combination;
      }

      return false;
    });
  }
}

export const ItemsService = new ItemsServiceClass();
