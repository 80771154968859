import * as Three from 'three';
import { AnimationWrapper, AssetsService, GameObjectClass, ParserService, PhysicsWrapper, TimeService } from 'three-default-cube';
import { ItemId, items, ItemsService } from '../../services/items-service';
import { PlayerService } from '../../services/player-service';
import { UntypedDefaultCube } from '../../types';
import { ParticleEmitterWrapper } from '../property-wrappers/particle-emitter';
import { TargettableWrapper } from '../property-wrappers/targettable-wrapper';

export class ItemGameObject extends GameObjectClass {
  physics = null;
  animationsWrapper = null;
  targettable = null;
  pickUpAnimation = null;
  onPickUp?: () => void;

  interactionCube?: Three.Object3D;
  model?: Three.Object3D;

  constructor({ itemId }: {
    itemId: ItemId
  }) {
    super();

    const itemDefinition = ItemsService.getItem(itemId);

    this.userData = {
      itemId,
      ...itemDefinition
    };

    AssetsService.getModel(require(`../../assets/models/items/${itemDefinition.modelId}.glb`)).then((model: Three.Object3D) => {
      this.model = model;

      if (model.animations) {
        this.animationsWrapper = new AnimationWrapper(model);
        this.animationsWrapper.playAllAnimations();

        this.animationsWrapper.blendInAnimation('idle', 1.0);
      }

      ParserService.parseModel(<UntypedDefaultCube>{
        target: model,
        gameObjects: {
          'fire': (object: Three.Object3D) => {
            TimeService.registerIntervalListener(() => {
              object.lookAt(new Three.Vector3(0.0, 1.0, 0.0).add(object.position));
            }, 30);
          },
          'particleEmitter': (object: Three.Object3D) => {
            new ParticleEmitterWrapper(object);
          }
        }
      });

      this.add(model);
    });
  }

  enablePhysics() {
    this.physics = new PhysicsWrapper(this);
    // this.physics.enableNavmaps();
  }

  disablePhysics() {
    if (!this.physics) {
      return;
    }

    this.physics.dispose();
    this.physics = null;
  }

  enablePickUp(onPickUp?: () => void) {
    this.enablePhysics();

    this.onPickUp = onPickUp;

    if (!this.targettable) {
      this.targettable = new TargettableWrapper(this, () => {
        if (this.onPickUp) {
          this.onPickUp();
        }

        this.onPickUp = null;

        const playerObject = PlayerService.getPlayerObject();

        this.quaternion.identity();
        this.position.set(0.0, 0.0, 0.0);

        playerObject.holdItem(this);

        this.disablePickUp();
      });
    }

    this.enableShowCase();

    this.targettable.enable();
  }

  enableShowCase() {
    if (!this.pickUpAnimation) {
      this.pickUpAnimation = TimeService.registerFrameListener(({ elapsedTime }) => {
        if (!this.model) {
          return;
        }

        this.model.position.y = Math.sin(elapsedTime) * 0.2 + 1.0;
        this.model.rotation.y += 0.005;
      });
    }
  }

  disablePickUp() {
    this.disablePhysics();

    if (this.model) {
      this.model.position.y = 0.0;
      this.model.rotation.y = 0.0;
    }
    
    if (this.targettable) {
      this.targettable.disable();
    }

    if (this.pickUpAnimation) {
      TimeService.disposeFrameListener(this.pickUpAnimation);
      
      this.pickUpAnimation = null;
    }
  }
}
