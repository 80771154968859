import { GameObjectClass, RenderService } from 'three-default-cube';
import { InteractionType } from '../environment/interaction';
import { InteractionTargetWrapper } from '../property-wrappers/interaction-target-wrapper';
import { ItemsService } from '../../services/items-service';
import { UntypedDefaultCube } from '../../types';

export class TreeGameObject extends GameObjectClass {
  constructor({ object }) {
    super();

    new InteractionTargetWrapper(object, [InteractionType.attack], ({ interactionPivot }) => {
      const item: UntypedDefaultCube = ItemsService.createItem('log-1');

      interactionPivot.getWorldPosition(item.position);
      item.enablePickUp();
    
      const scene = RenderService.getScene();
      scene.add(item);
    });
  }
}
