import * as Three from 'three';
import { AssetsService, UiService } from 'three-default-cube';
import { DialogueUi } from '../game-objects/ui/dialogue';
import { FishingUi } from '../game-objects/ui/fishing';
import { MenuUi } from '../game-objects/ui/menu';
import { UntypedDefaultCube } from '../types';

class WhyDungeonsUiServiceClass {
  dialogueUi?: DialogueUi;
  menuUi?: MenuUi;
  fishingUi?: FishingUi;

  createUi() {
    UiService.tween = 1.0;
    (<UntypedDefaultCube>UiService).uiScene.add(new Three.AmbientLight(0xffffff, 4.0));

    this.dialogueUi = new DialogueUi();
    this.menuUi = new MenuUi();
    this.fishingUi = new FishingUi();

    this.fishingUi.visible = false;

    AssetsService.registerDisposable(this.dialogueUi);
    AssetsService.registerDisposable(this.menuUi);
    AssetsService.registerDisposable(this.fishingUi);
  }

  showDialogue(text: string | string[]): Promise<boolean> {
    return this.dialogueUi.showDialogue(text);
  }
}

export const WhyDungeonsUiService = new WhyDungeonsUiServiceClass();