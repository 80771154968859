import * as Three from 'three';
import { GameInfoService, GameObjectClass, AssetsService, ParserService, UiService, mathPi2, Text, VarService, AnimationService, TimeService, MathService, MathUtils } from 'three-default-cube';
import { UntypedDefaultCube } from '../../types';

export class FishingUi extends GameObjectClass {
  constructor() {
    super();

    UiService.registerUiElement(this);
    this.position.z -= 5.0;

    (<UntypedDefaultCube>AssetsService).getModel(GameInfoService.config.models['fishingUi'], { forceUniqueMaterials: true })
    .then((model) => {
      this.add(model);

      (<UntypedDefaultCube>ParserService).parseModel({
        target: model,
        gameObjects: {
          'fishingButton': (object) => {
            (<UntypedDefaultCube>AnimationService).registerAnimation({
              target: object,
              onStep: ({ target }) => {
                const initialScale = MathService.getVec3(1.0, 1.0, 1.0);

                target.scale.lerp(initialScale, 0.2);
              }
            });
          },
        },
        actions: {
          'fishingContextAction': (object, { stopPropagation }) => {
            stopPropagation();

            if (this.fishingActionCallback) {
              this.fishingActionCallback();
            }

            if (object.visible) {
              object.scale.setScalar(1.1);
            }
          }
        }
      });
    });
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  onFishingButtonClick(callback) {
    this.fishingActionCallback = callback;
  }
}
