import * as Three from 'three';
import { DebugFlags, DummyDebug, GameInfoService, PhysicsService, RenderService, SystemService, VarService } from 'three-default-cube';
import { FireShader } from './game-shaders/fire';
import { FlowingUiShader } from './game-shaders/flowing-ui';
import { WaterShader } from './game-shaders/water';
import { InteriorSurface } from './game-surfaces/interior';
import { WaterSurface } from './game-surfaces/water';
import { LoginView } from './game-views/login';
import { WorldView } from './game-views/world';
import { PlayerService } from './services/player-service';
import { ZoneService } from './services/zone-service';

// DummyDebug.on(DebugFlags.DEBUG_ENABLE);
// DummyDebug.on(DebugFlags.DEBUG_LIVE);
// DummyDebug.on(DebugFlags.DEBUG_LOG_ASSETS);
// DummyDebug.on(DebugFlags.DEBUG_LOG_MEMORY);
// DummyDebug.on(DebugFlags.DEBUG_LOG_POOLS);
// DummyDebug.on(DebugFlags.DEBUG_STORAGE);
// DummyDebug.on(DebugFlags.DEBUG_TIME_LISTENERS);

GameInfoService
  .system(60, 1.0, true, true, 0x000000)
  .camera(50, 0.1, 1000.0)

  .font('default', require('./assets/ui/Prodush.ttf'))

  .model('intro', require('./assets/models/intro.glb'))

  .model('preloader', require('./assets/models/ui/preloader.glb'))
  .model('dialogueUi', require('./assets/models/ui/dialogue.glb'))
  .model('menuUi', require('./assets/models/ui/menu.glb'))
  .model('fishingUi', require('./assets/models/ui/fishing.glb'))
  .model('icons', require('./assets/models/ui/icons.glb'))

  .model('hero', require('./assets/models/hero.glb'))
  .model('horse', require('./assets/models/horse.glb'))
  .model('fishingSpot', require('./assets/models/fishing-spot.glb'))
  .model('cat', require('./assets/models/cat.glb'))
  .model('alchemyMachine', require('./assets/models/alchemy-machine-1.glb'))

  .model('zoneLogin', require(`./assets/models/zones/login.glb`))
  .model('zoneIslaBeliarek', require(`./assets/models/zones/isla-beliarek.glb`))
  .model('zoneBanjerHideout', require(`./assets/models/zones/banjer-hideout.glb`))

  .shader('water', WaterShader)
  .shader('fire', FireShader)
  .shader('flowingUi', FlowingUiShader)

  .texture('lightHdri', require('./assets/textures/lilienstein_1k.hdr'))
  .texture('skybox1', require('./assets/textures/skyboxes/skybox-1.hdr'))
  .texture('skybox2', require('./assets/textures/skyboxes/skybox-2.hdr'))
  .texture('skybox3', require('./assets/textures/skyboxes/skybox-3.hdr'))
  .texture('skybox4', require('./assets/textures/skyboxes/skybox-4.hdr'))

  .vars({
    'cutscene': false,
    'cameraMode': 0
  });

PhysicsService.registerSurfaceHandler('interior', InteriorSurface);
PhysicsService.registerSurfaceHandler('water', WaterSurface);

SystemService.init();
SystemService.onReady(async () => {
  const rootElement = document.querySelector('#root');

  await VarService.registerPersistentVar('playerInfo', {});
  await PlayerService.init();
  
  await VarService.registerPersistentVar('tutorials', {
    flyingMerchant: false
  });

  RenderService.init({ domElement: rootElement, pixelRatio: window.devicePixelRatio });

  // ZoneService.onZoneChange(zone => {
  //   PlayerService.setPlayerZone(zone);
  //   RenderService.renderView(new WorldView({ zone }));
  // });
  // ZoneService.setZone(PlayerService.getPlayerZone());

  RenderService.renderView(new LoginView());

  RenderService.run();
});
