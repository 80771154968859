import * as Three from 'three';
import { AssetsService, createDefaultCube, InteractionEnums, InteractionsService, VarService, GameObjectClass, PhysicsWrapper } from 'three-default-cube';
import { InteractionType } from '../environment/interaction';

export class InteractionTargetWrapper extends GameObjectClass {
  pivot = null;
  onInteraction = null;
  allowedInteractions = [];
  physics = null;

  constructor(
    target: Three.Object3D,
    allowedInteractions: Array<InteractionType> = [],
    onInteraction: ({ interactionPivot, sourceObject }: { interactionPivot: Three.Object3D, sourceObject: Three.Object3D }) => void
  ) {
    super();

    this.allowedInteractions = allowedInteractions;
    this.onInteraction = onInteraction;

    this.pivot = createDefaultCube(target, 'pivot');
    this.pivot.userData.interactionTarget = this;
    this.pivot.visible = false;

    this.physics = new PhysicsWrapper(this.pivot); 
    this.physics.enableDynamicCollisions();
  }
}