import * as Three from 'three';
import { PhysicsWrapper, TimeService } from "three-default-cube";
import { ItemGameObject } from "../game-objects/environment/item";
import { UntypedDefaultCube } from '../types';

export class WaterSurface {
  target: Three.Object3D;
  elapsedTime = 0;

  constructor(target: Three.Object3D) {
    this.target = target;

    this.onCreate();
  }

  onCreate() {
    TimeService.registerFrameListener(({ elapsedTime }) => {
      this.elapsedTime = elapsedTime;
    });
  }

  onInteraction({ body }: { body: UntypedDefaultCube }) {
    if (!body || !(body.target instanceof ItemGameObject) || body.grounded) {
      return;
    }

    body.target.position.y = Math.sin(this.elapsedTime) * 0.05 - 0.6;
    body.target.rotation.y += Math.sin(this.elapsedTime) * 0.002;
    body.target.rotation.x += Math.sin(this.elapsedTime) * 0.001;
  }

  dispose() {
    if (this.target) {
      delete this.target;
    }
  }
}
