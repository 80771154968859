import { AiWrapper, AnimationWrapper, AssetsService, CameraService, createDefaultCube, GameObjectClass, InputService, InteractionEnums, InteractionsService, mathPi2, MathService, MathUtils, PhysicsWrapper, RenderService, replacePlaceholder, TimeService, UtilsService, VarService } from 'three-default-cube';
import { CameraMode } from '../../game-views/world';
import { WhyDungeonsUiService } from '../../services/whydungeons-ui-service';
import { InteractionGameObject, InteractionType } from '../environment/interaction';
import { ItemGameObject } from '../environment/item';
import { TargettableWrapper } from '../property-wrappers/targettable-wrapper';
import * as Three from 'three';
import { PlayerService } from '../../services/player-service';
import { Item, ItemId, ItemProperty, ItemsService } from '../../services/items-service';
import { UntypedDefaultCube } from '../../types';
import { ExplosiveWrapper } from '../property-wrappers/explosive-wrapper';

export enum SlotId {
  weapon = 'weapon',
  offhand = 'offhand',
  head = 'head',
  chest = 'chest',
  shoulderR = 'shoulderR',
  shoulderL = 'shoulderL',
  backStash = 'backStash',
  sideStash = 'sideStash',
}

export enum HeroActivityEnums {
  idle = 'idle',
  walk = 'walk',
  ride = 'ride',
  lookAt = 'lookAtTarget',
  reach = 'reach',
  attack = 'attack',
  interact = 'interact',
  fish = 'fish',
  fishCatch = 'fish-catch',
};

type PersonaDefinition = (HeroGameObject) => unknown;

const npcPersona: Record<string, PersonaDefinition> = {
  'tutorial1': (character) => {
    const targettable = new TargettableWrapper(character, () => new Promise<void>((resolve) => {
      const playerObject = PlayerService.getPlayerObject();
  
      character.ai.setTargetNode(playerObject);
      character.setActivity(HeroActivityEnums.lookAt);

      VarService.setVar('cameraMode', CameraMode.dialogue);

      RenderService.pauseRendering(() => {
        CameraService.follow(character);

        RenderService.resumeRendering();

        return WhyDungeonsUiService.showDialogue(
          [
            'Default Cube projects have to be started using a boilerplate.',
            'The entire framework is dependent on Ionic and Capacitor. It will not work without them - since it’s using a lot of their features to make your life easier. The boilerplate setup is described later on.',

            'Why is there a React dependency?',
            'Default Cube isn’t really using React, but react-scripts makes deployment a bit faster. In the future it may be removed (the size of the dependency is quite negligable compared to size of game assets anyway.)',

            'Do I need to know Three.js?',
            'Yep. Default Cube may make the most annoying parts of gamedev easier - but still heavily depends on your Three.js knowledge.',
          ]
        ).then(() => {
          VarService.setVar('cameraMode', CameraMode.follow);

          resolve();
        });
      });
    }));
    targettable.minDistance = 3.0;
  },
  'banjersCat1': (character) => {
    const targettable = new TargettableWrapper(character, () => new Promise<void>((resolve) => {
      const playerObject = PlayerService.getPlayerObject();
  
      character.ai.setTargetNode(playerObject);
      character.setActivity(HeroActivityEnums.lookAt);

      VarService.setVar('cameraMode', CameraMode.dialogue);

      RenderService.pauseRendering(() => {
        CameraService.follow(character);

        RenderService.resumeRendering();

        return WhyDungeonsUiService.showDialogue(
          [
            'Fish?',
          ]
        ).then(() => {
          VarService.setVar('cameraMode', CameraMode.follow);

          resolve();
        });
      });
    }));
    targettable.minDistance = 3.0;
  },
  'flyingMerchant1': (character) => {
    const targettable = new TargettableWrapper(character, () => new Promise<void>((resolve) => {
      const playerObject = PlayerService.getPlayerObject();
  
      character.ai.setTargetNode(playerObject);
      character.setActivity(HeroActivityEnums.lookAt);

      VarService.setVar('cameraMode', CameraMode.dialogue);

      const tutorials = VarService.getVar('tutorials');
      const playerHeldItem = playerObject.getItemInSlot(SlotId.weapon);

      RenderService.pauseRendering(() => {
        CameraService.follow(character);

        RenderService.resumeRendering();

        let dialogue;

        if (!tutorials.flyingMerchant) {
          dialogue = WhyDungeonsUiService.showDialogue(
            [
              'Hello friend!',
              'Welcome to the Flying Merchant Shop & Dispository',
              'Our company travels far away lands to bring exotic and valuable wares',
              'Take a look at what I brought you today ---',
              'Just do not hesitate too long, these items will be gone tomorrow',
              'replaced by new ones!'
            ]
          );

          VarService.setVar('tutorials', { ...tutorials, flyingMerchant: true });
        } else if (playerHeldItem) {
          dialogue = WhyDungeonsUiService.showDialogue(
            [
              `That is a fine ${playerHeldItem.userData.name} ---`,
              `I could give you ${playerHeldItem.userData.shopPrice * 0.5} gold for it ---`,
              'Do we have a deal?'
            ]
          );
        } else {
          dialogue = WhyDungeonsUiService.showDialogue(
            [
              'Hello friend!',
              `Just look at the magical things I've found for you!`
            ]
          );
        }

        return dialogue.then(() => {
          VarService.setVar('cameraMode', CameraMode.follow);

          resolve();
        });
      });
    }));
    targettable.minDistance = 3.0;
  }
};

export class HeroGameObject extends GameObjectClass {
  pivot?: HeroGameObject;
  model?: Three.Object3D;
  animationsWrapper = null;
  physics = null;
  ai = null;
  controlled: boolean = false;
  persona?: PersonaDefinition = null;

  activeAnimation = 'idle'; // FIXME Activity and animations seem almost identical, merge
  activity = HeroActivityEnums.idle;

  itemSlots: Record<SlotId, {
    item?: ItemGameObject,
    bone: Three.Object3D
  }> = {
    weapon: null,
    offhand: null,
    head: null,
    chest: null,
    shoulderR: null,
    shoulderL: null,
    backStash: null,
    sideStash: null,
  };

  constructor({ modelId, controlled, persona, equipment }: {
    modelId: string,
    controlled?: boolean,
    persona?: string,
    equipment?: {
      [key in SlotId]?: ItemId
    }
  }) {
    super();

    this.visible = false;
    this.controlled = controlled;
    this.persona = npcPersona[persona];
    this.userData.equipment = equipment || {};

    AssetsService.getModel(modelId, <UntypedDefaultCube>{ forceUniqueMaterials: true })
    .then((model: Three.Object3D) => {
      this.visible = true;

      this.add(model);

      if (controlled) {
        model.rotation.y += mathPi2;
      } else {
        model.rotation.y -= mathPi2;
      }

      this.pivot = this;
      this.model = model;

      this.onCreate();
    });
  }

  onCreate() {
    this.createAnimations(this.model);

    this.physics = new PhysicsWrapper(this.pivot);
    this.physics.enableNavmaps();
    this.physics.enableDynamicCollisions(() => {
      // NOTE Collision listener
    });
    // this.physics.enableNoClip();

    this.ai = new AiWrapper(this.pivot);

    if (this.controlled) {
      this.createControls();
      // this.createHighlight();
    } else {
      this.createBehaviours();
    }

    this.createEquipment();

    // NOTE Temp
    if (this.model.getObjectByName(`sloteyes`)) {
      this.model.getObjectByName(`sloteyes`).visible = false;
    }

    if (this.model.getObjectByName(`slotsmile`)) {
      this.model.getObjectByName(`slotsmile`).visible = false;
    }
  }

  createEquipment() {
    const mappedEquipment = Object.assign({}, this.itemSlots);

    Object.entries<ItemId>(this.userData.equipment || {}).forEach(([ slot, itemId ]) => {
      if (!itemId) {
        return;
      }

      mappedEquipment[slot] = new ItemGameObject({ itemId });
    });

    Object.keys(this.itemSlots).forEach((slotId) => {
      this.itemSlots[slotId] = {
        bone: this.model.getObjectByName(`bone${slotId}`),
        item: null
      };

      if (mappedEquipment[slotId]) {
        this.putItemInSlot(slotId as SlotId, mappedEquipment[slotId]);
      }
    });
  }

  createAnimations(model: Three.Object3D) {
    this.animationsWrapper = new AnimationWrapper(model);
    this.animationsWrapper.stopAllAnimations();
  }

  createHighlight() {
    const currentTime = (new Date()).getHours();
    
    if (currentTime >= 6 && currentTime <= 22) {
      return;
    }

    const light = new Three.PointLight(0xffff55, 2.0, 100.0, 50.0);
    light.position.y += 2.0;
    light.position.z -= 0.1;
    this.pivot.add(light);
  }

  createControls() {
    const pivot = this.pivot;

    let playerSpeed = 0.0;
    const maxPlayerSpeed = 0.1;

    TimeService.registerFrameListener(() => {
      const playerMountObject = PlayerService.getPlayerMountObject();

      if (playerMountObject) {
        this.setActivity(HeroActivityEnums.ride);
        this.setAnimation('saddled');
        this.stashWeapons();
      }

      if (!VarService.getVar('cutscene')) {
        if (![
          HeroActivityEnums.idle,
          HeroActivityEnums.walk,
        ].includes(this.activity)) {
          return;
        }

        const keyUp = InputService.keys['w'];
        const keyDown = InputService.keys['s'];
        const keyLeft = InputService.keys['a'];
        const keyRight = InputService.keys['d'];

        const velocity = MathService.getVec3(0.0, 0.0, 0.0);

        const cameraDirection = MathService.getVec3(0.0, 0.0, 0.0);
        RenderService.getNativeCamera().getWorldDirection(cameraDirection);
        cameraDirection.y = 0.0;

        const cameraDirectionSideways = MathService.getVec3(0.0, 0.0, 0.0);
        const up = MathService.getVec3(0.0, 1.0, 0.0);
        cameraDirectionSideways.copy(cameraDirection);
        cameraDirectionSideways.applyAxisAngle(up, mathPi2);
        MathService.releaseVec3(up);

        if (keyUp) {
          velocity.add(cameraDirection);
        }

        if (keyDown) {
          velocity.sub(cameraDirection);
        }

        if (keyLeft) {
          velocity.add(cameraDirectionSideways);
        }

        if (keyRight) {
          velocity.sub(cameraDirectionSideways);
        }

        if (velocity.length() > 0.0) {
          playerSpeed = MathUtils.lerp(playerSpeed, maxPlayerSpeed, 0.2);

          const direction = MathService.getVec3(0.0, 0.0, 0.0);
          const rotationMock = UtilsService.getEmpty();

          pivot.getWorldPosition(direction).sub(velocity);
          pivot.getWorldPosition(rotationMock.position);
          pivot.getWorldDirection(rotationMock.quaternion);
          rotationMock.lookAt(direction);

          pivot.quaternion.slerp(rotationMock.quaternion, 0.2);

          MathService.releaseVec3(direction);
          UtilsService.releaseEmpty(rotationMock);
        } else {
          playerSpeed = MathUtils.lerp(playerSpeed, 0.0, 0.5);
        }

        velocity.normalize().multiplyScalar(playerSpeed);

        this.physics.setSimpleVelocity(velocity);

        if (Math.abs(playerSpeed * (1 / maxPlayerSpeed)) > 0.25) {
          this.setAnimation('walk');
          this.activity = HeroActivityEnums.walk;
        } else {
          this.setAnimation('idle');
          this.activity = HeroActivityEnums.idle;
        }

        MathService.releaseVec3(velocity);
        MathService.releaseVec3(cameraDirection);
        MathService.releaseVec3(cameraDirectionSideways);
      } else {
        const aiBehaviour = this.ai.getAiBehaviour();

        if (!aiBehaviour) {
          if (this.activity === HeroActivityEnums.fish) {
            this.setAnimation('fishing-idle');
          } else if (this.activity === HeroActivityEnums.fishCatch) {
            this.setAnimation('fishing-catch');
          }

          return;
        }

        const { targetNode } = aiBehaviour;

        if (!targetNode) {
          if (this.animation === 'walk') {
            this.setAnimation('idle');
          }

          return;
        }

        this.setAnimation('walk');

        const targetNodePosition = MathService.getVec3();
        targetNode.getWorldPosition(targetNodePosition);

        targetNodePosition.y = pivot.position.y;

        const rotationMock = UtilsService.getEmpty();
        pivot.parent.add(rotationMock);

        rotationMock.position.copy(pivot.position);
        rotationMock.lookAt(targetNodePosition);
        rotationMock.rotateY(Math.PI);
        pivot.quaternion.slerp(rotationMock.quaternion, 0.2);
        
        const playerDirection = MathService.getVec3();
        pivot.getWorldDirection(playerDirection);

        this.physics.setSimpleVelocity(playerDirection.multiplyScalar(-maxPlayerSpeed));
        
        MathService.releaseVec3(targetNodePosition);
        MathService.releaseVec3(playerDirection);
        UtilsService.releaseEmpty(rotationMock);
      }
    });
  }

  createBehaviours() {
    if (!this.persona) {
      return;
    }

    this.persona(this);

    TimeService.registerFrameListener(() => {
      if (this.activity === HeroActivityEnums.idle) {
        this.setAnimation('idle');
      } else if (this.activity === HeroActivityEnums.lookAt) {
        this.setAnimation('walk');

        const targetPosition = MathService.getVec3();
        const rotationMock = UtilsService.getEmpty();

        this.ai.getTargetNode().getWorldPosition(targetPosition);
        this.pivot.getWorldPosition(rotationMock.position);
        rotationMock.lookAt(targetPosition);

        const angleToTarget = this.pivot.quaternion.angleTo(rotationMock.quaternion);

        if (angleToTarget < 0.01) {
          this.pivot.quaternion.copy(rotationMock.quaternion);

          this.setActivity(HeroActivityEnums.idle);
        } else {
          this.pivot.quaternion.slerp(rotationMock.quaternion, 0.2);
        }

        MathService.releaseVec3(targetPosition);
        UtilsService.releaseEmpty(rotationMock);
      } else {

      }
    });
  }

  setActivity(activity: HeroActivityEnums) {
    this.activity = activity;
  }

  setAnimation(animation: string = 'idle') {
    if (this.activeAnimation === animation) {
      return;
    }

    this.activeAnimation = animation;

    this.animationsWrapper.stopAllAnimations(250);
    this.animationsWrapper.playAnimation(this.activeAnimation, 250);
  }

  followPath(minDistance: number, onComplete: () => void) {
    this.ai.registerBehaviour(() => {
      const targetDistance = (this.ai.path.length <= 1 ? minDistance : null) || 0.5;

      if (this.ai.hasTargetNode() && this.ai.getDistanceToTargetNode() <= targetDistance) {
        this.ai.setTargetNode(null);
      }

      if (!this.ai.hasTargetNode() && this.ai.path.length === 0) {
        onComplete();

        this.ai.registerBehaviour(() => ({}));
      }

      return { targetNode: this.ai.getTargetNode() };
    });
  }

  putItemInSlot(slotId: SlotId, item: ItemGameObject, onSlotTaken?: (currentItem: ItemGameObject) => void) {
    if (!item || !slotId) {
      console.info('HeroGameObject', 'putItemInSlot', 'item or slot does not exist', { item, slotId });
      return;
    }

    if (typeof item !== 'object') {
      console.info('HeroGameObject', 'putItemInSlot', 'not an item object', { item, slotId });
      return;
    }

    const slot = this.itemSlots[slotId];

    if (!slot) {
      console.info('HeroGameObject', 'putItemInSlot', 'attempted to put item in a weird slot', { slotId, item });

      return;
    }

    if (slot.item) {
      if (slot.bone) {
        slot.bone.remove(<UntypedDefaultCube>slot.item);
      }

      slot.item.parent = null;

      if (onSlotTaken) {
        onSlotTaken(slot.item);
      }
    }

    slot.item = item;

    if (slot.bone) {
      slot.bone.add(<UntypedDefaultCube>item);

      item.quaternion.copy(slot.bone.quaternion);
      item.scale.copy(slot.bone.scale);
      item.position.set(0.0, 0.0, 0.0);
    }

    if (this.controlled && item.userData.itemId) {
      this.userData.equipment[slotId] = item.userData.itemId;

      PlayerService.updatePlayer();
    }
  }

  removeItemFromSlot(slotId: SlotId): ItemGameObject {
    if (!slotId) {
      return;
    }

    const slot = this.itemSlots[slotId];

    if (slot && slot.item) {
      const existingItem = slot.item;

      slot.bone.remove(<UntypedDefaultCube>slot.item);
      slot.item = null;

      if (this.controlled) {
        this.userData.equipment[slotId] = null;

        PlayerService.updatePlayer();
      }

      return existingItem;
    }
  }

  isSlotTaken(slotId: SlotId): boolean {
    if (!slotId) {
      return false;
    }

    const slot = this.itemSlots[slotId];

    return slot && !!slot.item;
  }

  hasItem(itemId: ItemId) {
    return Object.values(this.itemSlots).some(({ item }) => {
      return item && item.userData.itemId === itemId;
    });
  }

  hasItemInSlot(slotId: SlotId, itemId: ItemId) {
    return this.itemSlots[slotId] && this.itemSlots[slotId].item.userData.itemId === itemId;
  }

  getItemInSlot(slotId: SlotId): ItemGameObject {
    return this.itemSlots[slotId]?.item;
  }

  stashWeapons() {
    if (!this.isSlotTaken(SlotId.sideStash)) {
      this.putItemInSlot(SlotId.sideStash, this.removeItemFromSlot(SlotId.weapon));
    }

    if (!this.isSlotTaken(SlotId.backStash)) {
      this.putItemInSlot(SlotId.backStash, this.removeItemFromSlot(SlotId.offhand));
    }

    if (this.controlled) {
      PlayerService.updatePlayer();
    }
  }

  wieldWeapons() {
    this.putItemInSlot(SlotId.weapon, this.removeItemFromSlot(SlotId.sideStash), (item) => {
      this.dropItem(item);
    });
    this.putItemInSlot(SlotId.offhand, this.removeItemFromSlot(SlotId.backStash));

    if (this.controlled) {
      PlayerService.updatePlayer();
    }
  }

  holdItem(item: ItemGameObject, forceWeaponSlot = false) {
    if (item.userData.onPickUp) {
      item.userData.onPickUp(this);
    } else if (!item.userData.slot || item.userData.slot === SlotId.weapon || forceWeaponSlot) {
      this.stashWeapons();

      this.putItemInSlot(SlotId.weapon, item, (item) => this.dropItem(item));
    } else if (item.userData.slot) {
      this.putItemInSlot(item.userData.slot, item, (item) => this.dropItem(item));
    }

    if (this.controlled) {
      PlayerService.updatePlayer();
    }
  }

  dropItem(item: ItemGameObject) {
    if (!item) {
      return;
    }

    const scene = RenderService.getScene();
    
    const heroPosition = MathService.getVec3();
    const heroDirection = MathService.getVec3();

    this.pivot.getWorldPosition(heroPosition);
    this.pivot.getWorldDirection(heroDirection);

    item.quaternion.identity();

    scene.add(item);
    item.position.copy(heroPosition).sub(heroDirection);

    item.enablePickUp();

    if (this.controlled) {
      PlayerService.updatePlayer();
    }
  }

  moveToTarget(target: Three.Object3D, radius?: number): Promise<void> {
    return new Promise<void>((resolve) => {
      this.ai.setTargetNode(target);
      this.ai.findPathToTargetNode();

      this.physics.enableNoClip();

      this.followPath(radius, () => {
        this.physics.disableNoClip();

        const nullVelocity = MathService.getVec3();
        this.physics.setSimpleVelocity(nullVelocity);
        MathService.releaseVec3(nullVelocity);

        resolve();
      });
    });
  }

  contextualActivity() {
    if (![HeroActivityEnums.idle].includes(this.activity)) {
      return;
    }

    const velocity = MathService.getVec3(0.0, 0.0, 0.0);
    this.physics.setSimpleVelocity(velocity);
    MathService.releaseVec3(velocity);
    
    const scene = RenderService.getScene();
    const interaction = new InteractionGameObject(this, InteractionType.attack);
    this.pivot.getWorldPosition(interaction.position);

    const offset = MathService.getVec3();
    this.pivot.getWorldDirection(offset);
    interaction.position.sub(offset);

    const heldItem = this.getItemInSlot(SlotId.weapon);

    if (ItemsService.hasProperty(heldItem, ItemProperty.weaponThrowable)) {
      this.dropItem(heldItem);
      // heldItem.disablePickUp();

      if (ItemsService.hasProperty(heldItem, ItemProperty.weaponExplosive)) {
        new ExplosiveWrapper(heldItem);
      }
    } else {
      scene.add(interaction);
    }

    this.setActivity(HeroActivityEnums.attack);
    this.activeAnimation = 'attack';
    this.animationsWrapper.stopAllAnimations(0);
    this.animationsWrapper.playAnimation('attack', 0, true, () => {
      this.setActivity(HeroActivityEnums.idle);
      this.activeAnimation = 'idle';
      this.animationsWrapper.stopAllAnimations(0);
      this.animationsWrapper.playAnimation('idle', 300);

      if (interaction) {
        interaction.cancel();
      }
    });
  }
}
