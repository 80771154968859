import * as Three from 'three';
import { PMREMGenerator } from 'three';
import {
  ViewClass,
  Preloader,
  AssetsService,
  RenderService,
  SceneService,
  GameInfoService,
  CameraService,
  TimeService,
  MathUtils,
  AnimationService,
  VarService,
  MathService,
  UiService,
} from 'three-default-cube';
import { ItemSpawnGameObject } from '../game-objects/environment/item-spawn';
import { ParticleEmitterWrapper } from '../game-objects/property-wrappers/particle-emitter';
import { WhyDungeonsPreloader } from '../game-objects/ui/whydungeons-preloader';

export class LoginView extends ViewClass {
  onCreate() {
    const scene = RenderService.getScene();
    const currentTime = (new Date()).getHours();

    const isDaylight = currentTime >= 6 && currentTime <= 22;

    let ambientLight;
    let skybox;

    if (isDaylight) {
      ambientLight = AssetsService.getAmbientLight(0xff8e38, 0x285180, 2.0);
      skybox = GameInfoService.config.textures.skybox3;
    } else {
      ambientLight = AssetsService.getAmbientLight(0x56A6FF, 0x56A6FF, 1.0);
      skybox = GameInfoService.config.textures.skybox4;
    }

    scene.add(ambientLight);

    scene.background = new Three.Color(0x010103);

    VarService.setVar('loginLegal', 'Copyright WTL Studio 2020-');
    VarService.setVar('loginVersion', 'Version 1.0.0 TPIOD\n12.2021');
    VarService.setVar('loginStep', '');
    let loginStep = 0;

    let dots = 0;
    const dotsInterval = () => {
      VarService.setVar('loginStep', 'Preparing Adventure' + Array(dots).fill('.').join(''));

      dots = (dots + 1) % 4;
    };

    new WhyDungeonsPreloader({
      requireAssets: [
        AssetsService.getHDRI(GameInfoService.config.textures.skybox1),
        AssetsService.getHDRI(skybox),
        AssetsService.getModel(GameInfoService.config.models.zoneLogin),
      ],
      onComplete: ([
        refractionHdri,
        hdri,
        loginZone
      ]) => {
        scene.background = hdri;

        SceneService.parseScene({
          target: loginZone,
          gameObjects: {
            'whyCrystal': (object) => {
              const materials = [];
              const pivot = new Three.Group();

              pivot.add(object);
              pivot.position.copy(object.position);
              object.position.set(0.0, 0.0, 0.0);

              scene.add(pivot);

              object.traverse(child => {
                if (child.material) {
                  refractionHdri.mapping = Three.CubeUVRefractionMapping;
                  child.material.envMap = refractionHdri;
                  child.material.envMapIntensity = 0.1;

                  child.material.reflectivity = 1.0;

                  child.material.emissive = new Three.Color(0xff00ff);
                  child.material.emissiveIntensity = 0.0;

                  materials.push(child.material);
                }
              });

              AnimationService.registerAnimation({
                target: object,
                onCreate: () => {
                  pivot.userData.originalPosition = pivot.position.clone();

                  object.userData.oscillations = 0.0;

                  // setTimeout(() => {
                  //   TimeService.registerIntervalListener(dotsInterval, 1000);
                  // }, 2000);

                  // setTimeout(() => {
                  //   loginStep = 1;
                  // }, 10000);
                },
                onStep: ({ animationTime }) => {
                  if (loginStep === 0) {
                    materials.forEach(material => {
                      material.envMapIntensity = MathUtils.lerp(material.envMapIntensity, 2.0, 0.01);
                      material.emissiveIntensity = (Math.sin(animationTime) * 0.5 + 0.5) * 0.05 + 0.05;
                    });
                  } else if (loginStep === 1) {
                    materials.forEach(material => {
                      material.envMapIntensity = MathUtils.lerp(material.envMapIntensity, 5.0, 0.02);
                      material.emissiveIntensity = MathUtils.lerp(material.emissiveIntensity, 1.0, 0.02);
                    });

                    object.userData.oscillations = MathUtils.lerp(object.userData.oscillations, 0.01, 0.02);

                    setTimeout(() => {
                      object.rotation.y -= 0.02;

                      TimeService.disposeIntervalListener(dotsInterval);

                      VarService.setVar('loginStep', '');

                      loginStep = 2;
                    }, 1000);
                  } else if (loginStep === 2) {
                    pivot.position.y = MathUtils.lerp(pivot.position.y, pivot.userData.originalPosition.y + 0.5, 0.01);

                    object.rotation.y += 0.01;

                    if (pivot.position.y >= pivot.userData.originalPosition.y + 0.4) {
                      loginStep = 3;
                    }
                  } else if (loginStep === 3) {
                    object.rotation.y += 0.01;

                    if (object.rotation.y >= 2.0) {
                      loginStep = 4;
                    }
                  } else if (loginStep === 4) {
                    object.rotation.y += 0.01;

                    VarService.setVar('loginStep', 'Enter Dungeons');
                  }

                  const random = MathService.getVec3().random().multiplyScalar(MathUtils.randFloat(-object.userData.oscillations, object.userData.oscillations));

                  object.position.copy(random);

                  MathService.releaseVec3(random);
                }
              });
            },
            'crystalLight': (object) => {
              TimeService.registerFrameListener(() => {
                if (loginStep >= 1) {
                  object.traverse(child => {
                    if (child instanceof Three.Light) {
                      child.intensity = MathUtils.lerp(child.intensity, 50.0, 0.02);
                      child.intensity *= 0.9 + Math.random() * 0.1;
                    }
                  });
                }
              });
            },
            'particleEmitter': (object: Three.Object3D) => {
              new ParticleEmitterWrapper(object);
            },
            'itemSpawn': (object) => {
              new ItemSpawnGameObject(object, { showCaseMode: false });
            },
            'gameInfo': (object) => {
              UiService.registerUiElement(object.parent);
            },
            'wheel1': (object) => {
              TimeService.registerFrameListener(() => {
                object.rotation.y -= 0.002;
              });
            },
            'wheel2': (object) => {
              TimeService.registerFrameListener(() => {
                object.rotation.y -= 0.004;
              });
            },
            'frontlight': (object) => {
              object.traverse(child => {
                if (child.isLight) {
                  if (isDaylight) {
                    child.color.set(0xff8e38);
                    child.intensity = 10.0;
                  } else {
                    child.color.set(0x56A6FF);
                    child.intensity = 5.0;
                  }
                }
              });
            },
            'backlight': (object) => {
              object.traverse(child => {
                if (child.isLight) {
                  if (isDaylight) {
                    child.color.set(0xff8e38);
                    child.intensity = 5.0;
                  } else {
                    child.color.set(0x56A6FF);
                  }
                }
              });
            }
          },
          onCreate: () => {
            scene.add(loginZone);

            CameraService.useCamera(CameraService.getCamera('start'), true);
          }
        });
      }
    });
  }
}
