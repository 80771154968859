import * as Three from 'three';
import { GameInfoService, GameObjectClass, AssetsService, ParserService, UiService, mathPi2, Text, VarService, AnimationService, TimeService, MathService, MathUtils, removePlaceholder, replacePlaceholder } from 'three-default-cube';
import { PlayerService } from '../../services/player-service';
import { ZoneService } from '../../services/zone-service';
import { UntypedDefaultCube } from '../../types';
import { SlotId } from '../creatures/hero';

enum JournalPage {
  map,
  blank,
  inventory,
}

const createButton = (object, then) => {
  if (!object.userData.madeButton) {
    object.userData.madeButton = true;
    object.userData.originalScale = object.scale.clone();

    TimeService.registerFrameListener(() => {
      object.scale.lerp(object.userData.originalScale, 0.2);
    });
  }

  if (object.visible) {
    object.scale.setScalar(object.userData.originalScale.x * 1.2);

    setTimeout(() => {
      then();
    }, 100);
  }
}

const createRarityIndicator = (object, slotId) => {
  const material = object.material;

  material.emissive = new Three.Color(0x000000);
  material.emissiveIntensity = 0.2;

  const playerObject = PlayerService.getPlayerObject();
  const item = playerObject.getItemInSlot(slotId);

  if (item?.userData.rarity >= 3) {
    material.color.set(0xaaaaaa);
    material.emissive.set(0x9d00ff);
  } else if (item?.userData.rarity >= 2) {
    material.color.set(0xaaaaaa);
    material.emissive.set(0x00ff4c);
  } else {
    material.color.set(0xffffff);
    material.emissive.set(0x000000);
  }
}

export class MenuUi extends GameObjectClass {
  constructor() {
    super();

    const icons = {};

    UiService.registerUiElement(this);
    this.position.z -= 5.0;

    VarService.setVar('menuExpanded', false);
    VarService.setVar('menuButtonOffset', '0.1');

    VarService.getVar('cutscene', (value) => {
      this.visible = !value;
    });

    VarService.setVar('showJournal', false);
    VarService.setVar('journalActivePage', JournalPage.map);
    VarService.setVar('inventoryShowItemPreview', false);
    VarService.setVar('inventoryItemPreviewSlotId', null);
    VarService.setVar('inventoryItemPreviewName', null);

    VarService.getVar('playerUpdated', () => {
      VarService.setVar('journalPlayerGold', `0000${PlayerService.getPlayerInfo().gold || 0}`.substr(-4));
    });

    Promise.all([
      new Promise((resolve) => {
        AssetsService.getModel(GameInfoService.config.models['icons']).then(model => {
          ParserService.parseModel({
            target: model,
            gameObjects: {
              'icon': (object) => {
                const { iconId } = object.userData;

                icons[iconId] = object;
              }
            },
            onCreate: () => {
              resolve(null);
            }
          });
        });
      }),
      AssetsService.getModel(GameInfoService.config.models['menuUi'], { forceUniqueMaterials: true })
    ])
    .then(([
      iconsPromise,
      menuModel
    ]) => {
      this.add(menuModel);

      TimeService.registerIntervalListener(() => {
        const menuExpanded = VarService.getVar('menuExpanded');
        const currentMenuOffset = parseFloat(VarService.getVar('menuButtonOffset'));

        if (menuExpanded) {
          VarService.setVar('menuButtonOffset', `${MathUtils.lerp(currentMenuOffset, 0.7, 0.2)}`);
        } else {
          VarService.setVar('menuButtonOffset', `${MathUtils.lerp(currentMenuOffset, 0, 0.2)}`);
        }
      }, 16);

      ParserService.parseModel({
        target: menuModel,
        gameObjects: {
          'menuButton': (object) => {
            // 
          },
          'inventoryButton': (object) => {
            TimeService.registerFrameListener(() => {
                const initialScale = MathService.getVec3(1.0, 1.0, 1.0);

                object.scale.lerp(initialScale, 0.2);

                const offset = parseFloat(VarService.getVar('menuButtonOffset'));

                object.visible = offset >= 0.4;
            });
          },
          'journalButton': (object) => {
            TimeService.registerFrameListener(() => {
              const initialScale = MathService.getVec3(1.0, 1.0, 1.0);

              object.scale.lerp(initialScale, 0.2);

              const offset = parseFloat(VarService.getVar('menuButtonOffset'));

              object.visible = offset >= 0.4;
            });
          },
          'settingsButton': (object) => {
            TimeService.registerFrameListener(() => {
              const initialScale = MathService.getVec3(1.0, 1.0, 1.0);

              object.scale.lerp(initialScale, 0.2);

              const offset = parseFloat(VarService.getVar('menuButtonOffset'));

              object.visible = offset >= 0.4;
            });
          },
          'contextActionButton': (object) => {
          //   TimeService.registerFrameListener(() => {
          //     const initialScale = MathService.getVec3(1.0, 1.0, 1.0);

          //     object.scale.lerp(initialScale, 0.2);
          // });
          },
          'movementKnobBackground': (object) => {

          },
          'movementKnob': (object) => {

          },
          'mapPlayerIndicator': (object) => {
            TimeService.registerIntervalListener(() => {
              const playerObject = PlayerService.getPlayerObject();

              object.position.x = MathUtils.lerp(object.position.x, playerObject.position.x * 0.02, 0.1);
              object.position.y = MathUtils.lerp(object.position.y, playerObject.position.z * -0.02, 0.1);
            }, 1);
          },
          'mapMinimap': (object) => {
            const minimapGroup = new Three.Group();

            object.geometry = new Three.BufferGeometry();

            minimapGroup.rotation.x = mathPi2;
            object.add(minimapGroup);

            VarService.getVar('zoneMinimapObject', (minimap) => {
              minimapGroup.children = [];
              minimapGroup.add(minimap);

              minimap.position.set(0.0, 0.0, 0.0);
              minimap.scale.set(1.0, 1.0, 1.0);
            });
          },
          'journal': (object) => {
            VarService.getVar('showJournal', value => {
              object.visible = value;
            });
          },
          'journalMapButton': (object) => {
            // TimeService.registerFrameListener(() => {
            //   const initialScale = MathService.getVec3(1.0, 1.0, 1.0);

            //   object.scale.lerp(initialScale, 0.2);
            // });
          },
          'journalInventoryButton': (object) => {
            // TimeService.registerFrameListener(() => {
            //   const initialScale = MathService.getVec3(1.0, 1.0, 1.0);

            //   object.scale.lerp(initialScale, 0.2);
            // });
          },
          'journalBlankButton': (object) => {
            // TimeService.registerFrameListener(() => {
            //   const initialScale = MathService.getVec3(1.0, 1.0, 1.0);

            //   object.scale.lerp(initialScale, 0.2);
            // });
          },
          'journalMap': (object) => {
            object.position.set(0.0, 0.0, 0.1);

            VarService.getVar('journalActivePage', value => {
              object.visible = value === JournalPage.map;
            });
          },
          'journalInventory': (object) => {
            object.position.set(0.0, 0.0, 0.1);

            VarService.getVar('journalActivePage', value => {
              object.visible = value === JournalPage.inventory;

              if (!object.visible) {
                VarService.setVar('inventoryShowItemPreview', false);
              }
            });
          },
          'journalGold': (object) => {
            VarService.getVar('inventoryShowItemPreview', (value) => {
              object.visible = !value;
            });
          },
          'journalBlank': (object) => {
            object.position.set(0.0, 0.0, 0.1);

            VarService.getVar('journalActivePage', value => {
              object.visible = value === JournalPage.blank;
            });
          },
          'inventoryPlaceholder': (object) => {
            const { slotId } = object.userData;
            const playerObject = PlayerService.getPlayerObject();

            const previewGroup = new Three.Group();
            object.children = [];
            object.add(previewGroup);

            if (slotId === 'itemPreview') {
              AnimationService.registerAnimation({
                target: previewGroup,
                onCreate: () => {
                  previewGroup.userData.initialPosition = previewGroup.position.clone();
                },
                onStep: ({ animationTime }) => {
                  previewGroup.position.y = previewGroup.userData.initialPosition.y + Math.sin(animationTime) * 0.01;
                }
              });
            }

            const showItem = (item) => {
              previewGroup.children = [];

              if (item) {
                const { iconId } = item.userData;
  
                const icon = icons[iconId || 'unknown-1'].clone();
                icon.position.set(0.0, 0.0, 0.1);
  
                previewGroup.children = [];
                previewGroup.add(icon);
              }
            };

            if (slotId === 'itemPreview') {
              VarService.getVar('inventoryItemPreviewSlotId', slotId => {
                showItem(playerObject.getItemInSlot(slotId));

                createRarityIndicator(object, slotId);
              });
            } else {
              VarService.getVar('playerUpdated', () => {
                showItem(playerObject.getItemInSlot(slotId));

                createRarityIndicator(object, slotId);
              });
            }
          },
          'itemPreview': (object) => {
            object.position.set(0.0, 0.0, 0.3);

            VarService.getVar('inventoryShowItemPreview', value => {
              object.visible = value;
            });

            VarService.getVar('inventoryItemPreviewSlotId', slotId => {
              createRarityIndicator(object, slotId);
            });
          },
          'itemPreviewBackground': (object) => {
            VarService.getVar('inventoryItemPreviewSlotId', slotId => {
              createRarityIndicator(object, slotId);
            });
          },
          'itemPreviewDropButton': (object) => {
            // NOTE Rotate 180deg on x-axis if on a shop area
          },
          'inventoryItemPreviewRarity': (object) => {
            const { rarityLevel } = object.userData;
            
            let targetRotation = -Math.PI;
            object.rotation.y = -Math.PI;

            TimeService.registerFrameListener(() => {
              object.rotation.y = MathUtils.lerp(object.rotation.y, targetRotation, 0.2);
            });
            
            VarService.getVar('inventoryItemPreviewSlotId', slotId => {
              const playerObject = PlayerService.getPlayerObject();
              const item = playerObject.getItemInSlot(slotId);

              targetRotation = -Math.PI;
              object.rotation.y = -Math.PI;

              if (item && item.userData.rarity >= rarityLevel) {
                setTimeout(() => {
                  targetRotation = 0.0;
                }, 150 * (rarityLevel - 1));
              }
            });
          }
        },
        actions: {
          'openMenu': (object, { stopPropagation }) => {
            stopPropagation();

            createButton(object, () => {
              VarService.setVar('menuExpanded', !VarService.getVar('menuExpanded'));
            });
          },
          'openInventory': (object, { stopPropagation }) => {
            stopPropagation();

            createButton(object, () => {
              const playerObject = PlayerService.getPlayerObject();

              playerObject.dropItem(playerObject.removeItemFromSlot(SlotId.weapon));
              VarService.setVar('menuExpanded', false);
            });
          },
          'openJournal': (object, { stopPropagation }) => {
            stopPropagation();

            createButton(object, () => {
              VarService.setVar('menuExpanded', false);

              VarService.setVar('showJournal', !VarService.getVar('showJournal'));
            });
          },
          'openSettings': (object, { stopPropagation }) => {
            stopPropagation();

            createButton(object, () => {
              VarService.setVar('menuExpanded', false);
            });
          },
          'contextAction': (object, { stopPropagation }) => {
            stopPropagation();

            createButton(object, () => {
              const playerObject = PlayerService.getPlayerObject();
            
              playerObject.contextualActivity();
            });
          },
          'journalShowMap': (object, { stopPropagation }) => {
            stopPropagation();

            createButton(object, () => {
              VarService.setVar('journalActivePage', JournalPage.map);
            });
          },
          'journalInventory': (object, { stopPropagation }) => {
            stopPropagation();

            createButton(object, () => {
              VarService.setVar('journalActivePage', JournalPage.inventory);
            });
          },
          'showItemPreview': (object, { stopPropagation }) => {
            const { slotId } = object.userData;

            stopPropagation();

            createButton(object, () => {
              const playerObject = PlayerService.getPlayerObject();
              const item = playerObject.getItemInSlot(slotId);

              if (item) {
                VarService.setVar('inventoryItemPreviewName', item.userData.name);
                VarService.setVar('inventoryItemPreviewSlotId', slotId);
                VarService.setVar('inventoryShowItemPreview', true);
              } else {
                VarService.setVar('inventoryItemPreviewName', '');
                VarService.setVar('inventoryItemPreviewSlotId', '');
                VarService.setVar('inventoryShowItemPreview', false);
              }
            });
          },
          'journalBlank': (object, { stopPropagation }) => {
            stopPropagation();

            createButton(object, () => {
              VarService.setVar('journalActivePage', JournalPage.blank);
            });
          },
          'journalClose': (object, { stopPropagation }) => {
            stopPropagation();

            createButton(object, () => {
              VarService.setVar('showJournal', false);
              VarService.setVar('inventoryShowItemPreview', false);
            });
          },
          'itemPreviewHide': (object, { stopPropagation }) => {
            stopPropagation();

            createButton(object, () => {
              VarService.setVar('inventoryShowItemPreview', false);
            });
          },
          'itemPreviewHold': (object, { stopPropagation }) => {
            stopPropagation();

            createButton(object, () => {
              const slotId = VarService.getVar('inventoryItemPreviewSlotId');
              const playerObject = PlayerService.getPlayerObject();

              const item = playerObject.removeItemFromSlot(slotId);
              playerObject.holdItem(item, true);

              VarService.setVar('inventoryShowItemPreview', false);
            });
          },
          'itemPreviewUse': (object, { stopPropagation }) => {
            stopPropagation();

            createButton(object, () => {
              const slotId = VarService.getVar('inventoryItemPreviewSlotId');
              const playerObject = PlayerService.getPlayerObject();

              const item = playerObject.removeItemFromSlot(slotId);
              playerObject.holdItem(item, false);

              VarService.setVar('inventoryShowItemPreview', false);
            });
          },
          'itemPreviewDrop': (object, { stopPropagation }) => {
            stopPropagation();

            createButton(object, () => {
              const slotId = VarService.getVar('inventoryItemPreviewSlotId');
              const playerObject = PlayerService.getPlayerObject();

              const item = playerObject.removeItemFromSlot(slotId);
              playerObject.dropItem(item);

              VarService.setVar('inventoryShowItemPreview', false);
            });
          },
        }
      });
    });
  }
}
