import * as Three from 'three';
import {
  GameObjectClass, AssetsService, RenderService, TimeService, AnimationService, GameInfoService, fitToCamera, UiService, ParserService, SceneService
} from 'three-default-cube';
import { UntypedDefaultCube } from '../../types';

export class WhyDungeonsPreloader extends GameObjectClass {
  constructor({ requireAssets, onComplete }) {
    super();

    Promise.all([
      ...(requireAssets || []),
      TimeService.createTimeoutPromise(3000)
    ]).then(assets => {
      const complete = onComplete(assets);

      if (complete && complete.then) {
        complete.then(() => this.onLoaded());
      } else {
        this.onLoaded();
      }
    })

    const camera = RenderService.getNativeCamera();
    camera.add(this);

    UiService.registerUiElement(this);
    this.position.z -= 5.0;

    (<UntypedDefaultCube>AssetsService).getModel(GameInfoService.config.models.preloader, { forceUniqueMaterials: true })
    .then((model) => {
      this.add(model);

      const ambientLight = AssetsService.getAmbientLight(0xffffcc, 0x000033, 1000.0);
      this.add(ambientLight);

      (<UntypedDefaultCube>ParserService).parseModel({
        target: model,
        gameObjects: {
          background: (object) => {
            fitToCamera(object, camera);
          }
        }
      });
    });
  }

  onLoaded() {
    AssetsService.disposeAsset(this);
  }
}
