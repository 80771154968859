import * as Three from 'three';
import {
  ViewClass,
  AssetsService,
  CameraService,
  RenderService,
  SceneService,
  GameInfoService,
  PhysicsService,
  TimeService,
  AnimationService,
  PhysicsWrapper,
  mathPi2,
  VarService,
  MathService,
  removePlaceholder,
  UiService,
} from 'three-default-cube';
import { FishingSpotGameObject } from '../game-objects/environment/fishing-spot';
import { HeroGameObject, SlotId } from '../game-objects/creatures/hero';
import { MountGameObject } from '../game-objects/creatures/mount';
import { TreeGameObject } from '../game-objects/creatures/tree';
import { BookGameObject } from '../game-objects/environment/book';
import { ChestGameObject } from '../game-objects/environment/chest';
import { InteractionType } from '../game-objects/environment/interaction';
import { InteractionTargetWrapper } from '../game-objects/property-wrappers/interaction-target-wrapper';
import { WhyDungeonsPreloader } from '../game-objects/ui/whydungeons-preloader';
import { ItemId, ItemsService } from '../services/items-service';
import { WhyDungeonsUiService } from '../services/whydungeons-ui-service';
import { ZoneId, ZoneService } from '../services/zone-service';
import { AlchemyMachineGameObject } from '../game-objects/creatures/alchemy-machine';
import { PlayerService } from '../services/player-service';
import { UntypedDefaultCube } from '../types';
import { FireGameObject } from '../game-objects/environment/fire';
import { ItemSpawnGameObject } from '../game-objects/environment/item-spawn';

export enum CameraMode {
  follow,
  dialogue
};

export class WorldView extends ViewClass {
  zone: ZoneId;

  constructor({ zone }: {
    zone: ZoneId
  }) {
    super();

    this.zone = zone;
  }

  async onCreate() {
    (PhysicsService as any).gravityConstant = -0.493;
    (PhysicsService as any).slopeTolerance = 0.75;

    const currentTime = (new Date()).getHours();

    // VarService.setVar('envLightDaylightCycle', currentTime >= 6 && currentTime <= 22 ? '#ffffcc' : '#0000ff');
    VarService.setVar('envLightDaylightCycle', '#ffffcc');

    VarService.setVar('flyingMerchantArrived', currentTime >= 6 && currentTime <= 22 ? true : false);
    VarService.setVar('flyingMerchantArrived', true);
    VarService.setVar('flyingMerchantItem1', 'shield-1');
    VarService.setVar('flyingMerchantItem2', 'axe-2');
    VarService.setVar('flyingMerchantItem3', 'hammer-1');

    this.createEnvironment();
  }

  createEnvironment() {
    new WhyDungeonsPreloader({
      requireAssets: [
        AssetsService.getHDRI(GameInfoService.config.textures.lightHdri),
        AssetsService.getModel(GameInfoService.config.models[this.zone])
      ],
      onComplete: ([
        hdri,
        zoneModel
      ]) => {
        SceneService.setEnvironment(hdri);

        this.createWorld(zoneModel);
        this.createPlayer();
      }
    });
  }

  createPlayer() {
    const scene = RenderService.getScene();
    const playerInfo = PlayerService.getPlayerInfo();

    const playerObject = new HeroGameObject({
      modelId: GameInfoService.config.models.hero,
      controlled: true,
      equipment: (playerInfo.equipment as Record<SlotId, ItemId>)
    });
    const spawnTarget = ZoneService.getPlayerSpawnTarget();

    if (spawnTarget) {
      playerObject.visible = false;
    }

    if (playerInfo.position) {
      playerObject.position.copy(playerInfo.position);
    }

    playerObject.userData.zone = this.zone;
    
    playerObject.position.y += 2.0;
    scene.add(playerObject);

    PlayerService.setPlayerObject(playerObject);

    const playerCameraUpdate = TimeService.registerFrameListener(() => {
      const cameraMode: CameraMode = VarService.getVar('cameraMode');
      const targetCameraPosition = MathService.getVec3();

      if (cameraMode === CameraMode.follow) {
        targetCameraPosition.set(0.0, 10.5, 9.5);
      } else if (cameraMode === CameraMode.dialogue) {
        targetCameraPosition.set(0.0, 5.0, 5.5);
      }

      CameraService.followPivotPosition.lerp(targetCameraPosition, CameraService.tween);
    });

    const updatePlayerInfoInterval = TimeService.registerIntervalListener(() => {
      if (AssetsService.isDisposed(playerObject)) {
        return;
      }
      
      PlayerService.setPlayerInfo({
        ...playerObject.userData,
        position: playerObject.position
      });
    }, 300);

    AssetsService.registerDisposeCallback(playerObject, () => {
      TimeService.disposeFrameListener(playerCameraUpdate);
      TimeService.disposeIntervalListener(updatePlayerInfoInterval, 300);
    });

    RenderService.pauseRendering();
    CameraService.tween = 1.0;
    CameraService.lockRotation();
    CameraService.follow(playerObject, null, false);

    setTimeout(() => {
      CameraService.tween = 0.1;
      RenderService.resumeRendering();
    }, 1000);
  }

  createWorld(zoneModel) {
    const scene = RenderService.getScene();

    VarService.setVar('cutscene', false);

    SceneService.parseScene({
      target: zoneModel,
      gameObjects: {
        'floraSmall': (object) => {
          AnimationService.registerAnimation({
            target: object,
            randomSeed: Math.random() * 10,
            onStep: ({ target, animationTime }) => {
              target.rotation.x = Math.sin(animationTime) * 0.1;
            }
          });
        },
        'floraMedium': (object) => {
          AnimationService.registerAnimation({
            target: object,
            randomSeed: Math.random() * 100,
            onStep: ({ target, animationTime }) => {
              // target.rotation.x = Math.sin(animationTime) * -0.05;
            }
          });

          new TreeGameObject({ object });
        },
        'floraBig': (object) => {
          AnimationService.registerAnimation({
            target: object,
            randomSeed: Math.random() * 1000,
            onStep: ({ target, animationTime }) => {
              target.rotation.x = Math.sin(animationTime) * -0.01;
            }
          });

          new TreeGameObject({ object });
        },
        'floraMushroom': (object) => {
          AnimationService.registerAnimation({
            target: object,
            randomSeed: Math.random() * 1000,
            onStep: ({ target, animationTime }) => {
              target.scale.setScalar(1.0 + Math.sin(animationTime) * 0.01);
            }
          });
        },
        'itemSpawn': (object) => {
          new ItemSpawnGameObject(object);
        },
        'npcSpawn': (object) => {
          const { propNpcId, propNpcModelVariantId } = object.userData;

          if (propNpcId === 'alchemyMachine') {
            const alchemyMachine = new AlchemyMachineGameObject();

            zoneModel.add(alchemyMachine);
            alchemyMachine.position.copy(object.position);
          } else {
            const character = new HeroGameObject({
              modelId: GameInfoService.config.models[propNpcModelVariantId || 'hero'],
              controlled: false,
              persona: propNpcId,
              equipment: {
                head: 'horse-head-helmet-1'
              }
            });

            zoneModel.add(character);
            character.position.copy(object.position);  
          }

          removePlaceholder(object);
        },
        'door': (object, { gameObjectRefs }) => {
          const { doorId, keyId } = object.userData;
          const doorObject = gameObjectRefs[doorId];
          const triggerObject = object;
          let isOpen = false;

          object.visible = false;

          if (!doorObject) {
            console.info('Cannot find door', { doorId, gameObjectRefs });

            return;
          }

          const physics = new PhysicsWrapper(triggerObject);
          physics.enableDynamicCollisions(() => {
            if (keyId && !VarService.getVar(`doorUnlocked${doorId}`)) {
              const playerObject = PlayerService.getPlayerObject();

              if (playerObject.hasItem(keyId)) {
                VarService.registerPersistentVar(`doorUnlocked${doorId}`, true);
              } else {
                return;
              }
            }

            isOpen = true;
          });

          TimeService.registerFrameListener(() => {
            if (isOpen) {
              doorObject.rotation.y = Three.MathUtils.lerp(doorObject.rotation.y, mathPi2, 0.2);
            } else {
              doorObject.rotation.y = Three.MathUtils.lerp(doorObject.rotation.y, 0.0, 0.2);
            }

            isOpen = false;
          });
        },
        'wallTop': (object) => {
          VarService.getVar('playerInInteriors', (value) => {
            object.visible = !value;
          });
        },
        'target': (object) => {
          const { propTargetId } = object.userData;
          const spawnTarget = ZoneService.getPlayerSpawnTarget();

          object.visible = false;

          if (!spawnTarget || propTargetId !== spawnTarget) {
            return;
          }

          const playerObject = PlayerService.getPlayerObject();
          const targetPosition = MathService.getVec3();

          object.getWorldPosition(targetPosition);

          playerObject.position.copy(targetPosition);
          playerObject.position.y += 0.1;

          playerObject.visible = true;

          MathService.releaseVec3(targetPosition);
        },
        'portal': (object) => {
          const { propPortalZone, propPortalTargetId } = object.userData;
          const playerObject = PlayerService.getPlayerObject();

          object.visible = false;

          const physics = new PhysicsWrapper(object);
          physics.enableDynamicCollisions((hit) => {
            const cutscene = VarService.getVar('cutscene'); 

            if (hit !== playerObject || !playerObject.visible || cutscene) {
              return;
            }

            VarService.setVar('cutscene', true);

            playerObject.position.set(0.0, 0.0, 0.0);

            ZoneService.setPlayerSpawnTarget(propPortalTargetId);
            ZoneService.setZone(propPortalZone);
          });
        },
        'sceneConfig': (object) => {
          const { lightIntensity, lightColor } = object.userData;

          object.visible = false;

          VarService.resolveVar(lightColor, value => {
            const ambientLight = AssetsService.getAmbientLight(value || 0xffffcc, 0x0000ff, (lightIntensity * 4.0) || 4.0);
            scene.add(ambientLight);

            (UiService as any).uiScene.add(ambientLight.clone());
          });
        },
        'minimap': (object) => {
          ZoneService.setMinimapObject(object);
        },
        'logObstacle': (object) => {
          new InteractionTargetWrapper(object, [InteractionType.attack], () => {
            object.rotation.x += Math.PI;
          });
        },
        'rockObstacle': (object) => {
          const { rockId } = object.userData;

          if (rockId) {
            VarService.getVar(rockId, (value) => {
              if (value) {
                return AssetsService.disposeAsset(object);
              }
            });
          }

          new InteractionTargetWrapper(object, [InteractionType.explosion], () => {
            if (rockId) {
              VarService.registerPersistentVar(rockId, true);
            }

            AssetsService.disposeAsset(object);
          });
        },
        'fishingSpot': (object) => {
          new FishingSpotGameObject({ object });
        },
        'fire': (object) => {
          new FireGameObject({ object });
        },
        'book': (object) => {
          new BookGameObject(object);
        },
        'chest': (object) => {
          new ChestGameObject(object);
        },
        'horse': (object) => {
          new MountGameObject({
            placeholder: object,
            modelId: GameInfoService.config.models.horse
          });
        },
      },
      onCreate: () => {
        scene.add(zoneModel);
        
        WhyDungeonsUiService.createUi();
      }
    });
  }
}
