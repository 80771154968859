import { AnimationWrapper, AssetsService, GameInfoService, GameObjectClass, RenderService, replacePlaceholder, VarService } from 'three-default-cube';
import { InteractionType } from './interaction';
import { InteractionTargetWrapper } from '../property-wrappers/interaction-target-wrapper';
import { FishingService } from '../../services/fishing-service';
import { ItemsService } from '../../services/items-service';
import { PlayerService } from '../../services/player-service';
import { UntypedDefaultCube } from '../../types';

export class FishingSpotGameObject extends GameObjectClass {
  constructor({ object }) {
    super();

    (<UntypedDefaultCube>AssetsService).getModel(GameInfoService.config.models.fishingSpot, { forceUniqueMaterials: true })
    .then(model => {
      replacePlaceholder(object, model);
      
      const animations = new AnimationWrapper(model);
      animations.stopAllAnimations();
      animations.blendInAnimation('idle', 1.0);

      new InteractionTargetWrapper(model, [InteractionType.attack], ({ interactionPivot }) => {
        FishingService.startFishing({
          onSuccess: () => {
            const playerObject = PlayerService.getPlayerObject();
            const item = ItemsService.createItem('fish-1');
            playerObject.getWorldPosition(item.position);
            item.enablePickUp();

            const scene = RenderService.getScene();
            scene.add(item);
          }
        });
      });
    });
  }
}