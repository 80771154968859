import * as Three from 'three';
import { AnimationService, AnimationWrapper, AssetsService, CameraService, GameObjectClass, InputService, InteractionEnums, InteractionsService, mathPi2, MathService, MathUtils, ParserService, PhysicsWrapper, RenderService, replacePlaceholder, TimeService, UtilsService, VarService } from 'three-default-cube';
import { PlayerService } from '../../services/player-service';
import { UntypedDefaultCube } from '../../types';

export class MountGameObject extends GameObjectClass {
  pivot: Three.Object3D;
  model: Three.Object3D;
  animations = null;
  physics = null;

  controlled: boolean = false;
  saddleObject: Three.Object3D = null;

  constructor({ placeholder, modelId }) {
    super();

    const mount = this;

    placeholder.visible = false;

    (<UntypedDefaultCube>AssetsService).getModel(modelId, { forceUniqueMaterials: true })
    .then(model => {
      (<UntypedDefaultCube>ParserService).parseModel({
        target: model,
        actions: {
          'mount': () => {
            const playerObject = PlayerService.getPlayerObject();

            playerObject.physics.enableNoClip();
            this.physics.enableNoClip();

            playerObject.ai.setTargetNode(model);
            playerObject.ai.findPathToTargetNode();

            VarService.setVar('cutscene', true);

            playerObject.followPath(0.5, () => {
              PlayerService.setPlayerMountObject(mount);

              RenderService.pauseRendering(() => {
                CameraService.follow(model);
                RenderService.resumeRendering();
              });

              this.controlled = true;

              VarService.setVar('cutscene', false);
            });
          }
        },
        gameObjects: {
          'mountPoint': (object) => {
            this.saddleObject = object;

            (<UntypedDefaultCube>AnimationService).registerAnimation({
              target: object,
              onCreate: ({ target }) => {
                target.userData.position = target.position.clone();
                target.userData.rotation = target.rotation.clone();
              },
              onStep: ({ target, animationTime }) => {
                const velocity = this.physics ? this.physics.getSimpleVelocity().lengthSq() : 0.0;

                if (this.controlled && velocity > 0.005) {
                  target.position.y = MathUtils.lerp(target.position.y, target.userData.position.y + Math.sin(animationTime * 15.0) * 0.1 + 0.1, 0.1);
                  target.rotation.z = MathUtils.lerp(target.rotation.z, target.userData.rotation.z + Math.sin(animationTime * 1.3) * 0.15, 0.1);
                  target.rotation.x = MathUtils.lerp(target.rotation.x, target.userData.rotation.x + Math.cos(animationTime) * 0.1, 0.1);
                } else {
                  target.position.y = MathUtils.lerp(target.position.y, target.userData.position.y + Math.sin(animationTime) * 0.05 + 0.05, 0.1);
                  target.rotation.z = MathUtils.lerp(target.rotation.z, target.userData.rotation.z + Math.sin(animationTime * 1.3) * 0.05, 0.1);
                  target.rotation.x = MathUtils.lerp(target.rotation.x, target.userData.rotation.x + Math.cos(animationTime) * 0.05, 0.1);
                }
              }
            })

            object.visible = false;
          }
        },
        onCreate: () => {
          placeholder.visible = true;

          replacePlaceholder(placeholder, model);
          model.rotation.y = mathPi2;

          this.pivot = placeholder;
          this.model = model;
    
          this.onCreate();
        }
      });
    });
  }

  onCreate() {
    this.createAnimations();
    this.createControls();
  }

  createAnimations() {
    (<UntypedDefaultCube>AnimationService).registerAnimation({
      target: this.model,
      onCreate: ({ target }) => {
        target.traverse(child => {
          if (child.material) {
            child.material.emissive = new Three.Color(0xffff33);
            child.material.emissiveIntensity = 0.0;
          }
        });
      },
      onStep: ({ target, animationTime }) => {
        let value = animationTime;

        if (this.controlled) {
          value = 0.0;
        }

        target.traverse(child => {
          if (child.material) {
            child.material.emissiveIntensity = Math.abs(Math.sin(value) * 0.01);
          }
        });
      }
    });

    this.animations = new AnimationWrapper(this.model);
    this.animations.stopAllAnimations();
  }

  createControls() {
    const pivot = this.pivot;

    this.physics = new PhysicsWrapper(pivot);
    this.physics.enableNavmaps();

    let mountSpeed = 0.0;
    const maxMountSpeed = 0.12;
    const playerObject = PlayerService.getPlayerObject();

    TimeService.registerFrameListener(() => {
      if ((<UntypedDefaultCube>pivot).__disposed__ || !this.animations) {
        return false;
      }

      if (!this.controlled) {
        this.animations.blendInAnimation('idle', 1.0);
        this.animations.blendInAnimation('run', 0.0);
        return;
      }

      const keyUp = InputService.keys['w'];
      const keyDown = InputService.keys['s'];
      const keyLeft = InputService.keys['a'];
      const keyRight = InputService.keys['d'];

      const velocity = MathService.getVec3(0.0, 0.0, 0.0);

      const cameraDirection = MathService.getVec3(0.0, 0.0, -1.0);
      cameraDirection.y = 0.0;

      const cameraDirectionSideways = MathService.getVec3(0.0, 0.0, 0.0);
      const up = MathService.getVec3(0.0, 1.0, 0.0);
      cameraDirectionSideways.copy(cameraDirection);
      cameraDirectionSideways.applyAxisAngle(up, mathPi2);
      MathService.releaseVec3(up);

      if (keyUp) {
        velocity.add(cameraDirection);
      }

      if (keyDown) {
        velocity.sub(cameraDirection);
      }

      if (keyLeft) {
        velocity.add(cameraDirectionSideways);
      }

      if (keyRight) {
        velocity.sub(cameraDirectionSideways);
      }

      if (velocity.length() > 0.0) {
        mountSpeed = Three.MathUtils.lerp(mountSpeed, maxMountSpeed, 0.1);

        const direction = MathService.getVec3(0.0, 0.0, 0.0);
        const rotationMock = UtilsService.getEmpty();

        pivot.getWorldPosition(direction).sub(velocity);
        pivot.getWorldPosition(rotationMock.position);
        pivot.getWorldDirection(rotationMock.quaternion);
        rotationMock.lookAt(direction);

        pivot.quaternion.slerp(rotationMock.quaternion, 0.2);

        MathService.releaseVec3(direction);
        UtilsService.releaseEmpty(rotationMock);
      } else {
        mountSpeed = Three.MathUtils.lerp(mountSpeed, 0.0, 0.1);
      }

      velocity.normalize().multiplyScalar(mountSpeed);

      this.physics.setSimpleVelocity(this.physics.getSimpleVelocity().clone().lerp(velocity, 0.2));

      this.animations.blendInAnimation('run', mountSpeed * (1 / maxMountSpeed));
      this.animations.blendInAnimation('idle', 1.0 - mountSpeed * (1 / maxMountSpeed));

      MathService.releaseVec3(velocity);
      MathService.releaseVec3(cameraDirection);
      MathService.releaseVec3(cameraDirectionSideways);

      playerObject.pivot.position.copy(pivot.position);
      playerObject.pivot.position.add(this.saddleObject.position);

      this.saddleObject.getWorldQuaternion(playerObject.pivot.quaternion);
      playerObject.pivot.rotateY(-mathPi2);
    });
  }
}
