import * as Three from 'three';
import { VarService } from "three-default-cube";

export class InteriorSurface {
  target: Three.Object3D;

  constructor(target: Three.Object3D) {
    this.target = target;

    this.onCreate();
  }

  onCreate() {
    this.target.visible = false;
  }

  onEnter() {
    VarService.setVar('playerInInteriors', true);
  }

  onLeave() {
    VarService.setVar('playerInInteriors', false);
  }

  dispose() {
    if (this.target) {
      delete this.target;
    }
  }
}
