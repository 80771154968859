import * as Three from 'three';
import { AssetsService, MathService, TimeService } from 'three-default-cube';

const generateFireTexture = () => {
  const resolution = 32;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = canvas.height = resolution;

  for (let i = 0; i < 5000; i++) {
    ctx.fillStyle = `hsl(0, 0%, ${Math.random() * 50 + 50}%)`;
    ctx.save();
    ctx.beginPath();

    const x = Math.random() * canvas.width;
    const y = Math.random() * canvas.height;

    const size = canvas.width / 20;
    const width = size + Math.random() * size;

    ctx.translate(x, y);
    ctx.arc(x, y, width, 0, Math.PI * 2, true);
    ctx.fill();

    ctx.restore();
  }

  const texture = new Three.CanvasTexture(canvas);
  texture.wrapS = Three.MirroredRepeatWrapping;
  texture.wrapT = Three.MirroredRepeatWrapping;

  return texture;
};

export const FireShader = ({ target }) => {
  const { userData } = target;

  const shader = {
    uniforms: {
      fDt: {
        value: 0.0
      },
      fSpeed: {
        value: 1.0
      },
      fFireHeatIntensity: {
        value: 3.2
      },
      fFireHeatOffset: {
        value: 1.4
      },
      tMapA: {
        type: 't',
        value: generateFireTexture()
      },
      tMapB: {
        type: 't',
        value: generateFireTexture()
      },
      vColor: {
        type: 'c',
        value: new Three.Color(userData.propFireColor || '#ff9442'),
      }
    },
    vertexShader: `
      varying vec4 vPos;

      void main() {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);

        vPos = viewMatrix * vec4(position + cameraPosition, 1.);
      }
    `,
    fragmentShader: `
      uniform sampler2D tMapA;
      uniform sampler2D tMapB;
      uniform float fDt;
      uniform float fSpeed;
      uniform float fFireHeatOffset;
      uniform float fFireHeatIntensity;
      uniform vec3 vColor;

      varying vec4 vPos;

      void main() {
        float mask = 0.75 - vPos.y * 0.5;
        vec2 uv = vec2(vPos.x * 0.25 + 0.5, vPos.y * 0.25 + 0.5);
        
        float dx = fSpeed * fDt;
        vec2 uvA = uv - vec2(dx * 0.5, dx);
        vec2 uvB = vec2(1.0 - uv.x, uv.y) - vec2(dx * 0.5, dx);

        vec4 texA = texture2D(tMapA, uvA);
        vec4 texB = texture2D(tMapB, uvB);
        vec3 tex = (texA + texB).xyz;

        gl_FragColor = vec4(mask * vColor.r + .6, mask / 2.0 * vColor.g + .2, mask / 8.0 * vColor.b + .1, mask * tex);

        vec3 texAbove = texture2D(tMapA, vec2(uvA.x, uvA.y + 0.01)).xyz;

        if (length(texAbove - tex) < .7) {
          gl_FragColor *= 1.5;
        }

        if (gl_FragColor.w < .98) {
          gl_FragColor.w = 0.0;
        } else {
          gl_FragColor.w = 1.0;
        }

        if (length(tex) >= 3.2) {
          gl_FragColor *= 1.2;
          gl_FragColor.w = 1.0;
        }

        vec4 heatMask = (texA + texB + vec4(mask)) / 4.0;

        if (length(heatMask) >= fFireHeatOffset && gl_FragColor.w > .9) {
          gl_FragColor *= fFireHeatIntensity;
          gl_FragColor.w = 1.0;
        }
      }
    `,
    transparent: true,
    side: Three.DoubleSide
  };

  TimeService.registerFrameListener(({ elapsedTime }) => {
    shader.uniforms.fDt.value += 0.01;
  });

  return shader;
};
