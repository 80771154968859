import * as Three from 'three';
import { AnimationService, CameraService, GameObjectClass, mathPi2, MathService, MathUtils, RenderService, VarService } from 'three-default-cube';
import { CameraMode } from '../../game-views/world';
import { ItemsService } from '../../services/items-service';
import { WhyDungeonsUiService } from '../../services/whydungeons-ui-service';
import { TargettableWrapper } from '../property-wrappers/targettable-wrapper';

const books = {
  banjerHideoutNote1: [
    'Dear Traveler -- My name is Banjer. I am sorry I could not meet you right away',
    'I need to leave briefly to finish my most recent experiment. I promise to be back soon and offer you a proper welcome',
    'If you by any chance meet a cat around -- I will be in a great debt, if you could take a good care of it',
    '-- Banjer'
  ],
  'flyingMerchantShopPendingNote': [
    'Be back soon - open between sunrise and sunset',
    '-- Patrick The Flying Merchant'
  ],
  'flyingMerchantShopNote1': () => {
    const item = ItemsService.getItem(VarService.getVar('flyingMerchantItem1'));

    if (!item.shopPrice) {
      return [
        `Ah, ${item.name} ...`,
        'But I am afraid I cannot sell it to you, friend'
      ];
    }
    
    return [
      `Ah, ${item.name} - a great choice!`,
      `It can be yours for just ${item.shopPrice} gold`,
      'Do we have a deal?'
    ];
  },
  'flyingMerchantShopNote2': () => {
    const item = ItemsService.getItem(VarService.getVar('flyingMerchantItem2'));

    if (!item.shopPrice) {
      return [
        `Ah, ${item.name} ...`,
        'But I am afraid I cannot sell it to you, friend'
      ];
    }
    
    return [
      `Ah, ${item.name} - a great choice!`,
      `It can be yours for just ${item.shopPrice} gold`,
      'Do we have a deal?'
    ];
  },
  'flyingMerchantShopNote3': () => {
    const item = ItemsService.getItem(VarService.getVar('flyingMerchantItem3'));

    if (!item.shopPrice) {
      return [
        `Ah, ${item.name} ---`,
        'But I am afraid I cannot sell it to you, friend'
      ];
    }
    
    return [
      `Ah, ${item.name} - a great choice!`,
      `It can be yours for just ${item.shopPrice} gold`,
      'Do we have a deal?'
    ];
  },
  'flyingMerchantShopAxe': [
    `That beauty, my friend, is a Drake Axe.`,
    'Found it when traveling over the Frozen Fjords',
    'I may be able to sell you one, as soon as I stumble upon another!'
  ]
};

export class BookGameObject extends GameObjectClass {
  model?: Three.Object3D;

  constructor(object) {
    super();

    this.model = object;

    this.onCreate();
  }

  onCreate() {
    const { propBookId } = this.model.userData;

    const targettable = new TargettableWrapper(this.model, () => new Promise<void>((resolve) => {
      VarService.setVar('cameraMode', CameraMode.dialogue);

      RenderService.pauseRendering(() => {
        CameraService.follow(this.model);

        RenderService.resumeRendering();

        let dialogue;

        if (typeof books[propBookId] === 'function') {
          dialogue = books[propBookId]();
        } else {
          dialogue = books[propBookId];
        }

        return WhyDungeonsUiService.showDialogue(dialogue).then(() => {
          VarService.setVar('cameraMode', CameraMode.follow);

          resolve();
        });
      });
    }));
    targettable.minDistance = 3.0;
  }
}