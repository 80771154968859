import * as Three from 'three';
import { AssetsService, MathService, TimeService } from 'three-default-cube';

export const WaterShader = () => {
  const flowDirection = MathService.getVec2(1.0, 0.0);

  const waterNormalA = AssetsService.getImageSync(require('../assets/textures/water/water-normal-a.jpg'));
  const waterNormalB = AssetsService.getImageSync(require('../assets/textures/water/water-normal-b.jpg'));

  waterNormalA.wrapS = Three.RepeatWrapping;
  waterNormalA.wrapT = Three.RepeatWrapping;

  waterNormalB.wrapS = Three.RepeatWrapping;
  waterNormalB.wrapT = Three.RepeatWrapping;

  const shader = {
    uniforms: {
      color: {
        type: 'c',
        value: new Three.Color(0x4263a6),
      },
      tNormal0: {
        type: 't',
        value: waterNormalA,
      },
      tNormal1: {
        type: 't',
        value: waterNormalB,
      },
      flowDirection: {
        type: 'v2',
        value: flowDirection
      }
    },
    vertexShader: `
      #include <common>
      #include <fog_pars_vertex>
      #include <logdepthbuf_pars_vertex>

      varying vec2 vUv;

      void main() {
        vUv = uv;

        vec4 worldPosition = modelMatrix * vec4( position, 1.0 );

        vec4 mvPosition = viewMatrix * worldPosition;
        gl_Position = projectionMatrix * mvPosition;

        #include <logdepthbuf_vertex>
        #include <fog_vertex>
      }
    `,
    fragmentShader: `
      #include <common>
      #include <fog_pars_fragment>
      #include <logdepthbuf_pars_fragment>

      uniform sampler2D tNormal0;
      uniform sampler2D tNormal1;
      uniform vec2 flowDirection;

      uniform vec3 color;
      varying vec2 vUv;

      void main() {
        #include <logdepthbuf_fragment>

        vec4 normalColor0 = texture2D(tNormal0, vUv + flowDirection );
        vec4 normalColor1 = texture2D(tNormal1, vUv + flowDirection.yx );
        vec4 normalColor = mix(normalColor0, normalColor1, .5);

        vec3 normal = normalize(vec3(normalColor.r * 2.0 - 1.0, normalColor.b, normalColor.g * 2.0 - 1.0));

        float reflectAngle = length(abs(normalColor.rgb - vec3(.5, .5, 1.0)));
        vec4 outColor = vec4(color, 1.0);

        if (reflectAngle < .1) {
          gl_FragColor = outColor;
        } else {
          gl_FragColor = mix(vec4(1., 1., 1., 1.), outColor, clamp((1.0 - reflectAngle) * 0.9, 0.0, 1.0));
        }

        #include <tonemapping_fragment>
        #include <encodings_fragment>
        #include <fog_fragment>
      }
    `,
    transparent: true,
  };

  TimeService.registerFrameListener(({ elapsedTime }) => {
    shader.uniforms.flowDirection.value.x = 0.4 * elapsedTime;
    shader.uniforms.flowDirection.value.x = 0.1 * elapsedTime;
  });

  return shader;
};
